var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Contact Us",
            "tabActive": "수원분사무소"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("C")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("ontact Us")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('sub-contact', {
    attrs: {
      "iframe": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3174.1517930127693!2d127.06578927635245!3d37.29153613984852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5b11bd2701bd%3A0xfe8f00df9aabd7ed!2z6rK96riw64-EIOyImOybkOyLnCDsmIHthrXqtawg6rSR6rWQ7KSR7JWZ66GcMjQ467KI6ri4IDk1LTE!5e0!3m2!1sko!2skr!4v1694507999927!5m2!1sko!2skr",
      "address": "경기도 수원시 영통구 광교중앙로 248번길 95-1, 7층 (광교법조타운)",
      "image": "/images/sub/contact/suwon/suwon-img.jpg",
      "tel": "031-213-0522"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }