var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Contact Us",
            "tabActive": "서울주사무소"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("C")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("ontact Us")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('sub-contact', {
    attrs: {
      "iframe": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.644687382891!2d127.00898542635954!3d37.49271027835712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca15e975930fb%3A0x8d8a8696999dee3c!2z7ZWc6rWt7JWE7J2067mE7JeQ7Iqk!5e0!3m2!1sko!2skr!4v1694506474644!5m2!1sko!2skr",
      "address": "서울시 서초구 서초대로 272, 9층 (한국아이비에스빌딩)",
      "image": "/images/sub/contact/seoul/seoul-img.jpg",
      "tel": "02-523-0522"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }