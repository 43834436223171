<template>
    <div class="user-items">
        <v-row align="center" class="row--sm">
            <slot />
            <v-col cols="auto" v-if="accessToken">
                <router-link to="/mypage/myinfo">
                    <u-icon v-if="icon">person_outline</u-icon>
                    <span v-if="text">Mypage</span>
                </router-link>
            </v-col>
            <v-col cols="auto" v-else>
                <router-link to="/join">
                    <u-icon v-if="icon">person_add_alt</u-icon>
                    <span v-if="text">Join</span>
                </router-link>
            </v-col>
            <v-col cols="auto" class="line-height-0"><v-divider vertical /></v-col>
            <v-col cols="auto">
                <router-link to="" @click.native="navigate">
                    <template v-if="accessToken">
                        <u-icon v-if="icon">logout</u-icon>
                        <span v-if="text">Logout</span>
                    </template>
                    <template v-else>
                        <u-icon v-if="icon">login</u-icon>
                        <span v-if="text">Login</span>
                    </template>
                </router-link>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {
        icon: { type: Boolean },
        text: { type: Boolean },
    },
    components: {
        UIcon,
    },
    methods: {
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
.user-items {
    ::v-deep {
        font-size: 1.4rem;
        color: var(--v-grey-base);

        > .row {
            > .col {
                line-height: 0;
            }
        }
        a {
            display: inline-flex;
            align-items: center;
            color: inherit;
            > .v-icon {
                font-size: 1.6rem !important;
                & + span {
                    margin-left: 4px;
                }
            }
        }

        .v-divider {
            border-color: var(--v-grey-lighten3);
            &--vertical {
                height: 12px;
            }
        }
    }

    &--light {
        color: #fff;
        .v-divider {
            border-color: rgba(255, 255, 255, 0.2);
        }
    }
}
</style>
