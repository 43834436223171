<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="Business">
                <template #titHead>B</template>
                <template #tit>usiness</template>
            </sub-visual>
        </template>
        <page-section class="page-section--first page-section--last">
            <v-row class="row--xl mx-xl-n60px">
                <v-col v-for="(item, index) in business" :key="index" cols="12" sm="6" class="px-xl-60px" :class="index !== 0 ? 'mt-20px mt-sm-0' : ''">
                    <v-card :href="item.href ? item.href : null" :target="item.href ? '_blank' : null" flat tile max-width="660"
                    :class="{ 'business-item--link': item.href }" class="business-item v-card--none-active-bg">
                        <v-img data-aos="fade-up" :src="item.image" max-width="660" :aspect-ratio="660 / 220" class="business-item__img w-100">
                            <div v-if="item.href" class="business-item__img-inner">
                                <v-row align="center" justify="center" class="row--xs white--text">
                                    <v-col cols="auto">
                                        <p class="font-size-14 font-weight-medium">사이트로 이동</p>
                                    </v-col>
                                    <v-col cols="auto">
                                        <icon-arrow-primary direction="right" size="small" class="mt-4px" />
                                    </v-col>
                                </v-row>
                            </div>
                        </v-img>
                        <div class="pt-16px pt-md-24px">
                            <u-tit-default data-aos="fade-up" class="business-item__index tit--sm grey--text text--lighten-1 font-weight-regular font-secondary mb-8px mb-md-16px">
                                <span v-if="index < 9">0</span>{{index+1}}
                            </u-tit-default>
                            <v-row data-aos="fade-up" data-aos-delay="200" align="center">
                                <v-col cols="auto">
                                    <u-tit-medium class="tit--sm line-height-14">{{item.title}}</u-tit-medium>
                                </v-col>
                                <v-col v-if="item.href" cols="auto">
                                    <btn-primary small class="v-btn--none-active-bg">자세히 보기</btn-primary>
                                </v-col>
                            </v-row>                                    
                            <u-txt-default data-aos="fade-up" data-aos-delay="400" class="txt--sm break-keep mt-8px mt-md-16px">{{item.txt}}</u-txt-default>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </page-section>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        IconArrowPrimary,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
        BtnPrimary,
    },
    data() {
        return {
            business: [
                {
                    title : "성범죄센터",
                    txt : "무죄인 성범죄가 유죄로 선고되어서도, 유죄인 성범죄가 무죄로 선고되어서도 안 됩니다. (실제 문유진 대표변호사는 성범죄재판부 판사 시절, 1심에서 유죄로 선고되었던 사건인데 항소심에서 재판부합의를 거쳐 항소심재판부가 결론을 완전히 뒤바꾸어 무죄를 선고했던 적이 있습니다.) 판심에서는 성범죄재판부 판사 출신 변호사의 독자적인 성범죄 유무죄 노하우로 성범죄의 실체적 진실에 맞는 적극적 대응과 방어가 가능합니다.",
                    image : "/images/sub/business/business-img.jpg",
                    href : "http://judgemind.com/",
                },
                {
                    title : "이혼·상속센터",
                    txt : "판심 법무법인은 이혼·상속으로 인한 분쟁이 끝나고, 10년이 지난 다음에도 ‘그래, 그 때 최선의 선택이고 결론이었어’ 라고 여길 수 있는 분쟁 해결이 되도록 판심 Client와 함께 가족에 대한, 인생에 대한 심도 깊은 고민을 함께 합니다. (판심은 단순히 사건만 기계적으로 처리하는 곳이 아니며, 여러분의 인생에 진정한 동반자가 되도록 최선을 다합니다.) 판심과 함께 하십시오. 그 어느 곳에서도 얻을 수 없는, 인생 최상의 결과를 선택하고, 그 선택이 여러분의 장래를 계속 이끌 것입니다.",
                    image : "/images/sub/business/business-img8.jpg",
                },
                {
                    title : "교통센터",
                    txt : "우리는 모두 운전자, 운행자, 보행자, 동승자, 승객으로 교통범죄의 잠재적 가해자이자 잠재적 피해자입니다. (살면서 피하기 어려운 교통범죄와 교통사고로 인한 피해) 교통재판부 판사 출신 대표변호사가 진두지휘하는 판심은 민형사 사건을 유기적이고 체계적으로 진행하는 민형사에 면허취소까지 한 자리에서 해결하는 원스톱 시스템을 갖추고 있습니다.",
                    image : "/images/sub/business/business-img2.jpg",
                },
                {
                    title : "형사센터",
                    txt : "변호사를 변호하는 문유진변호사(변변변)! 형사재판의 피고인이 되면 누구에게 맡길까요? 변호사가 형사재판에 연루되면 맡기는 변호사가 문유진 변호사입니다. 같은 업계의 변호사가 자신의 형사사건을 의뢰하는 변호사, 수많은 성공사례를 자랑하는 로펌의 대표로부터 사건을 의뢰받는 변호사가 문유진 변호사입니다.",
                    image : "/images/sub/business/business-img3.jpg",
                },
                {
                    title : "부패(사기,횡령,배임) 센터",
                    txt : "투자와 사기 그 명확한 경계는 어디일까요? (사실 판사들도 부패범죄의 유무죄를 판단하기를 까다로워하고, 어려워합니다.) 타인의 돈으로 일으킨 사업이 성공하면 투자가 되고, 망하면 사기로 고소당하기도 한다는 점이 그 애매한 지점인 것입니다. 부패재판부 판사 출신 변호사의 오랜 유무죄 판단베이스에 대한 노하우로 부패범죄의 실체적 진실에 맞는 적극적 대응과 방어가 가능합니다.",
                    image : "/images/sub/business/business-img4.jpg",
                },
                {
                    title : "구속영장실질심사센터",
                    txt : "구속영장이 청구된 피의자는 24시간 판심구속TEAM과 실시간 연락 가능하며, 피고인의 방어권 보호를 위해서는 불구속 상태에서 수사가 진행될 수 있도록 총력을 다합니다. 실제 구속영장 실질심문을 진행하고 구속영장 발부 여부를 결정한 판사 출신 변호사는 최상의 법률적 해결책으로 초기수사부터 적극적인 대응방안을 모색합니다. 동종 집행유예 중의 동종범죄의 구속영장 기각을 이끌어내는 변호사, 문유진 변호사는 가능합니다!",
                    image : "/images/sub/business/business-img5.jpg",
                },
                {
                    title : "학교센터(아동학대·행정)",
                    txt : "교권침해, 교사폭행, 학교폭력. 징계, 교내성폭력, 순직인정, 공무상 재해, 업무상 재해. 아동학대고소 양천 신강초등학교 교사폭행사건 대리, 서이초등학교 유족을 대리한 검증된 변호사, 문유진 변호사가 진두지휘합니다.",
                    image : "/images/sub/business/business-img6.jpg",
                },
                {
                    title : "민사센터",
                    txt : "판심 법무법인은 Client가 지속가능한 성장을 하도록 돕습니다. 당신의 민사분쟁, 엄격한 기업윤리를 가지고 책임을 다 하는 판심에 믿고 맡기셔야 합니다.",
                    image : "/images/sub/business/business-img7.jpg",
                },
                {
                    title : "건설·부동산센터",
                    txt : "판심은 재건축·재개발 조합의 설립, 조합총회·예산 등 조합 운영에 관한 각종 자문, 용역대금 분쟁소송, 매도청구, 명도 등 민사소송 및 관련 가처분, 조합설립인가·사업계획승인·관리처분계획인가 관련 무효·취소 소송 등에 관한 해결책을 체계적으로 제시합니다.",
                    image : "/images/sub/business/business-img9.jpg",
                },
                {
                    title : "기업법무센터",
                    txt : "판심은 폭넓은 실무경험과 끊임없는 고민으로 설립단계에서부터, 경영, 상장 등 모든 단계에서 고객의 Needs에 최대로 부합하는 법률서비스를 제공하고 있습니다. 서울대 법대 판사출신 대표변호사를 비롯하여 서울대 법대 법무법인 태평양에서 기업법무팀 근무 변호사, 서울대 경영대 한국거래소 근무 변호사, 서울대 경영대 차석입학, 최우등졸업 변호사, 검사 출신 변호사가 공동전담하여 최고의 기업법무서비스를 제공합니다.",
                    image : "/images/sub/business/business-img10.jpg",
                },
            ]
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.business-item{
    &__img-inner{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .5);
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-out;
        >*{
            opacity: 0;
            visibility: hidden;
            transform: scale(1.2);
            transition: 0.3s ease-out;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .business-item{
        &--link:hover{
            .business-item__img-inner{
                opacity: 1;
                visibility: visible;
                >*{
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                    transition: 0.5s ease-out;
                }
            }
        }
    }
}
@media (min-width:1200px){
}

</style>
