<template>
    <scrolldown v-bind="$attrs" class="scrolldown--mouse">
        <div class="scrolldown__mouse"></div>
        <div v-if="arrow" class="scrolldown__arrow">
            <slot v-if="this.$slots['arrow']" name="arrow" />
            <template v-else>
                <icon-chevron size="small" direction="down" />
                <icon-chevron size="small" direction="down" />
            </template>
        </div>
        <div v-else class="scrolldown__txt">Scroll</div>
    </scrolldown>
</template>

<script>
import Scrolldown from "@/components/publish/styles/scrolldowns/scrolldown.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";

export default {
    props: {
        arrow: { type: Boolean, default: false },
    },
    components: {
        Scrolldown,
        IconChevron,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
