<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="The firm" tabActive="비전">
                <template #titHead>T</template>
                <template #tit>he Firm</template>
            </sub-visual>
        </template>
        <page-section class="page-section--sm page-section--first">
            <template #pageSectionHead>
                <v-img data-aos="fade-up" src="/images/sub/firm/vision/vision-img.jpg" max-width="1920" :aspect-ratio="1920 / 500" class="w-100 mx-auto" />
            </template>
            <div class="mt-40px mt-md-80px">
                <v-row>
                    <v-col cols="12" md="6">
                        <u-tit-default data-aos="fade-up" class="tit--lg font-weight-bold line-height-14">
                            유관기관 협력 프로그램
                        </u-tit-default>
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="pl-md-20px pl-lg-96px">
                            <v-row v-for="(detail, index) in info" :key="index" :class="index !== 0 ? 'mt-16px mt-md-32px' : ''" no-gutters>
                                <v-col data-aos="fade-up" cols="auto">
                                    <u-tit-default class="tit--sm font-weight-regular secondary--text pr-10px pr-md-32px">0{{index+1}}</u-tit-default>
                                </v-col>
                                <v-col data-aos="fade-up" data-aos-delay="200">
                                    <u-txt-default class="txt--lg">
                                        {{detail}}
                                    </u-txt-default>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </page-section>
        <page-section class="pt-0 page-section--last">
            <v-row no-gutters class="border-t border-l border-b">
                <v-col v-for="(item, index) in cert" :key="index" cols="12" class="border-r">
                    <div class="pa-20px pa-md-30px py-lg-60px px-lg-58px">
                        <v-img data-aos="fade-up" :src="item.image" max-width="900" :aspect-ratio="900 / 600" class="w-100 mx-auto" />
                        <u-tit-default data-aos="fade-up" class="tit--sm font-weight-bold line-height-14 text-center mt-16px mt-md-32px"><span v-html="item.title"></span></u-tit-default>
                    </div>
                </v-col>
            </v-row>
        </page-section>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            info: [
                "사회적 기업으로서의 판심의 문은, 판심의 기업윤리와 결이 맞는 유관기관에 활짝 열려있습니다. 판심은 유관기관과의 끊임없는 협력으로 사회적 가치를 실현하고자 합니다.",
                "AI 인공지능이 펼쳐나갈 새로운 첨단기술이 지배하는 미래세상 속에서도 '사람 중심'의 성숙한 시민사회가 이어질 수 있도록 판심이 그 중심을 잡겠습니다.",
            ],
            cert: [
                {
                    title: "한국디지털포렌식센터",
                    image: "/images/sub/firm/vision/vision-agreement-01.jpg",
                },
                {
                    title: "경기교사노동조합",
                    image: "/images/sub/firm/vision/vision-agreement-02.jpg",
                },
                {
                    title: "유앤문 정신건강의학과",
                    image: "/images/sub/firm/vision/vision-agreement-03.jpg",
                },
                {
                    title: "부모와 아이 마음 의원",
                    image: "/images/sub/firm/vision/vision-agreement-04.jpg",
                },
                {
                    title: "우영회계법인",
                    image: "/images/sub/firm/vision/vision-agreement-05.jpg",
                },
                {
                    title: "최상산부인과",
                    image: "/images/sub/firm/vision/vision-agreement-06.jpg",
                },
            ]
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
