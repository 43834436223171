<template>
    <u-table-color-bg v-bind="$attrs" class="v-data-table--density-compact v-data-table--line border-1">
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
    </u-table-color-bg>
</template>

<script>
import UTableColorBg from "@/components/publish/styles/tables/u-table-color-bg.vue";

export default {
    props: {},
    components: {
        UTableColorBg,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
