<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="Contact Us" tabActive="인천분사무소">
                <template #titHead>C</template>
                <template #tit>ontact Us</template>
            </sub-visual>
        </template>
        <sub-contact
        iframe="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d791.9492321630047!2d126.66685616964966!3d37.44190176305178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b79a416b78189%3A0x3984c891254cf627!2z7J247LKc6rSR7Jet7IucIOuvuOy2lO2ZgOq1rCDshozshLHroZwgMTcx!5e0!3m2!1sko!2skr!4v1701928629137!5m2!1sko!2skr"
        address="인천 미추홀구 소성로 171 대흥평창로시스빌딩 로시스동 3층"
        image="/images/sub/contact/incheon/incheon-img.jpg" 
         />
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import SubContact from "@/sets/styles/sub/sub-contact.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        SubContact,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
