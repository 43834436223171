<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>menu-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn color="primary" id="menu-primary">Menu Primary</v-btn>
                <menu-primary activator="#menu-primary">
                    <v-list>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                    </v-list>
                </menu-primary>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" id="menu-secondary">Menu Secondary</v-btn>
                <menu-secondary activator="#menu-secondary">
                    <v-list>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                    </v-list>
                </menu-secondary>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" id="menu-tertiary">Menu Tertiary</v-btn>
                <menu-tertiary activator="#menu-tertiary">
                    <v-list>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                    </v-list>
                </menu-tertiary>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" id="menu-quarternary">Menu Quarternary</v-btn>
                <menu-quarternary activator="#menu-quarternary">
                    <v-list>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                    </v-list>
                </menu-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Default -->
        <h1>Default</h1>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn color="primary" id="menu-default">Menu Default</v-btn>
                <v-menu activator="#menu-default">
                    <v-list>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" id="menu-default-tile">Menu Default Tile</v-btn>
                <v-menu activator="#menu-default-tile" content-class="rounded-0">
                    <v-list>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                        <v-list-item><v-list-item-title>Menu</v-list-item-title></v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <!-- // Default -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import MenuPrimary from "@/components/publish/parents/menus/menu-primary.vue";
import MenuSecondary from "@/components/publish/parents/menus/menu-secondary.vue";
import MenuTertiary from "@/components/publish/parents/menus/menu-tertiary.vue";
import MenuQuarternary from "@/components/publish/parents/menus/menu-quarternary.vue";

export default {
    props: {},
    components: {
        GuideApp,

        MenuPrimary,
        MenuSecondary,
        MenuTertiary,
        MenuQuarternary,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
