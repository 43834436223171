var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    scopedSlots: _vm._u([{
      key: "pageSectionHead",
      fn: function () {
        return [_c('v-img', {
          staticClass: "w-100 mx-auto",
          attrs: {
            "data-aos": "fade-up",
            "src": _vm.image,
            "max-width": "1920",
            "aspect-ratio": 1920 / 500
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "sub-image-title__contents pt-40px pt-md-80px"
  }, [_c('div', {
    staticClass: "sub-image-title__title"
  }, [_c('u-tit-default', {
    staticClass: "tit--xxl",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pt-16px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._t("symbol")], 2)]), this.$slots['leftContents'] ? _c('v-col', {
    staticClass: "mt-20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('div', {
    staticClass: "pl-md-20px pl-lg-96px"
  }, [_vm._t("leftContents")], 2)]) : _vm._e()], 1), this.$slots['footContents'] ? _c('div', {
    staticClass: "pt-40px pt-md-80px"
  }, [_vm._t("footContents")], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }