<template>
    <swiper-pagination v-bind="$attrs"><slot /></swiper-pagination>
</template>

<script>
import SwiperPagination from "@/components/publish/styles/swiper/swiper-pagination.vue";

export default {
    props: {},
    components: { SwiperPagination },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
