<template>
    <u-scrolldown-arrow v-bind="$attrs" reverse>
        <template v-slot:arrow>
            <icon-arrow-rounded size="small" direction="down" class="text-grey" />
        </template>
    </u-scrolldown-arrow>
</template>

<script>
import UScrolldownArrow from "@/components/publish/styles/scrolldowns/u-scrolldown-arrow.vue";
import IconArrowRounded from "@/components/publish/styles/icons/icon-arrow-rounded.vue";

export default {
    props: {},
    components: {
        UScrolldownArrow,
        IconArrowRounded,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
