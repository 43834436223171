<template>
    <u-header-search-outlined-pill v-bind="$attrs">
        <slot />
    </u-header-search-outlined-pill>
</template>

<script>
import UHeaderSearchOutlinedPill from "@/components/publish/styles/headers/u-header-search-outlined-pill.vue";

export default {
    props: {},
    components: {
        UHeaderSearchOutlinedPill,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
