<template>
    <v-row class="row--xxs">
        <v-col v-if="!dotNone" cols="auto">
            ·
        </v-col>
        <v-col v-else cols="auto">
            &nbsp;
        </v-col>
        <v-col>
            <slot />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        dotNone: { type: Boolean, default: false },
    },
}
</script>

<style>

</style>