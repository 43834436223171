<template>
    <u-header-fixed-scroll class="header--lnb-all">
        <u-header-body-basic class="d-none d-xl-block">
            <template #left>
                <logo-primary>판심 법무법인</logo-primary>
            </template>
            <template #right>
                <btn-hamburger-primary onclick="alert('준비중입니다.');return false;" class="btn-hamburger--pc" />
            </template>
        </u-header-body-basic>
        <!-- S: Sitemap -->
        <!-- <sitemap-primary /> -->
        <!-- E: Sitemap -->
    </u-header-fixed-scroll>
</template>

<script>
import LogoPrimary from "@/components/publish/parents/headers/logo-primary.vue";
import LanguageItemsPrimary from "@/components/publish/parents/headers/language-items-primary.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";
import SitemapPrimary from "@/sets/parents/headers/sitemap-primary.vue";

import UHeaderBasic from "@/sets/styles/headers/u-header-basic.vue";
import UHeaderFixedScroll from "@/sets/styles/headers/u-header-fixed-scroll.vue";
import UHeaderBodyBasic from "@/sets/styles/headers/u-header-body-basic.vue";
import UHeaderFootBasic from "@/sets/styles/headers/u-header-foot-basic.vue";

import Gnb from "@/components/publish/styles/headers/gnb.vue";
import UGnbBasic from "@/components/publish/styles/headers/u-gnb-basic.vue";
import UGnbFilled from "@/components/publish/styles/headers/u-gnb-filled.vue";
import UGnbTopline from "@/components/publish/styles/headers/u-gnb-topline.vue";
import UGnbUnderline from "@/components/publish/styles/headers/u-gnb-underline.vue";

export default {
    components: {
        LogoPrimary,
        LanguageItemsPrimary,
        BtnHamburgerPrimary,
        SitemapPrimary,

        UHeaderBasic,
        UHeaderFixedScroll,
        UHeaderBodyBasic,
        UHeaderFootBasic,

        Gnb,
        UGnbBasic,
        UGnbFilled,
        UGnbTopline,
        UGnbUnderline,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
<style lang="scss" scoped>
.app--main{
    .header{
        .logo{
            ::v-deep{
                a{
                    background-image: url(/images/logo.svg);
                }
            }
        }
        ::v-deep{
            .header-body{
                transition: .2s ease-out;
                height: auto;
                padding: 36px 0;
            }
        }
    }
    .header.scroll-active{
        background-color: rgba(0, 0, 0, .6);
        ::v-deep{
            .header-body{
                padding: 20px 0;
            }
        }
    }
}
</style>