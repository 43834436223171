<template>
    <div class="border-r border-l">
        <v-divider v-if="topLine" :style="dark ? 'border-color: rgba(255, 255, 255, .2)' : ''" />
        <div class="pa-20px pa-md-30px pa-lg-48px">
            <v-row class="row--xl">
                <v-col data-aos="fade-up" cols="12" md="6" class="mb-n8px mb-md-0">
                    <u-tit-medium class="tit--sm line-height-14" :class="dark ? 'white--text' : ''">{{title}}</u-tit-medium>
                </v-col>
                <v-col cols="12" md="6">
                    <u-txt-default class="txt--lg" :class="dark ? 'white--text' : ''">
                        <slot />
                    </u-txt-default>
                </v-col>
            </v-row>
        </div>
        <v-divider :style="dark ? 'border-color: rgba(255, 255, 255, .2)' : ''" />
    </div>
</template>

<script>
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {
        title: { type: String, default: "" },
        dark: { type: Boolean, default: false },
        topLine: { type: Boolean, default: false },
    },
    components: {
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
}
</script>

<style>

</style>