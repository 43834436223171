<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="The firm" tabActive="판심 법무법인 소개">
                <template #titHead>T</template>
                <template #tit>he Firm</template>
            </sub-visual>
        </template>
        <sub-image-title title="Sustainability" image="/images/sub/firm/about/about-img.jpg" class="page-section--first">
            <template #symbol>
                <v-img src="/images/sub/firm/about/about-img2.svg" :max-width="$vuetify.breakpoint.mdAndUp ? 180 : 100" :aspect-ratio="180 / 202" />
            </template>
            <template #leftContents>                
                <u-txt-default data-aos="fade-up" class="txt--lg break-keep">
                    <p>
                        판심(判心) 은 의뢰인에 입장에서 생각합니다. <br />
                        누구나 순간의 실수와 오해로 가해자, 피해자가 될 수있습니다.
                    </p>
                    <p>
                        아무나 해결할 수 없는 형사사건, <br />
                        첫 대응, 첫 시작을 판심(判心)과 함께 하세요.
                    </p>
                </u-txt-default>
                <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--sm font-weight-bold line-height-14 mt-16px mt-md-32px">                    
                    판사출신 변호사의 경험과 풍부한 노하우로 <br class="d-none d-lg-block">
                    <span class="primary--text text--lighten-3">여러분의 인생의 조력자</span>가 되겠습니다.
                </u-tit-default>
            </template>
        </sub-image-title>
        <sub-image-title title="Project" image="/images/sub/firm/about/about-img3.jpg" class="pt-0 page-section--last">
            <template #symbol>
                <v-img src="/images/sub/firm/about/about-img4.svg" :max-width="$vuetify.breakpoint.mdAndUp ? 222 : 120" :aspect-ratio="222 / 128" />
            </template>
            <template #footContents>      
                <v-row>
                    <v-col cols="12">     
                        <div class="pr-lg-78px">
                            <u-txt-default data-aos="fade-up" class="txt--lg break-keep">
                                <p>
                                    법률분쟁에 직면하게 되면, 짧게는 수개월, 길게는 수년 동안
                                    고통스러운 시간을 보내게 됩니다. <br>
                                    판심은 매 사건마다 프로젝트를 구성하여 오랜 판사 경험을 통해 축적한 전문성을 바탕으로
                                    신속하고 정확한 해결책을 제시해 줄 것입니다.
                                </p>
                            </u-txt-default>
                            <div class="pt-24px pt-md-48px">
                                <v-img data-aos="fade-up" data-aos-delay="200" src="/images/sub/firm/about/about-icon.svg" :max-width="$vuetify.breakpoint.mdAndUp ? 40 : 30" :aspect-ratio="40 / 36" contain class="mb-12px mb-md-24px" />
                                <u-tit-medium data-aos="fade-up" data-aos-delay="400" class="tit--sm line-height-14 font-primary">
                                    더불어 Client의 마음을 보듬어 <br class="d-none d-lg-block">
                                    걱정 없던 시절로 복귀하여 평온한 일상을 <br class="d-none d-lg-block">
                                    다시 영위하도록 하는 것. <br><br>
                                    <strong class="font-weight-bold">이것이 <span class="primary--text text--lighten-3">“프로젝트 심”의 바람입니다.</span></strong>
                                </u-tit-medium>
                            </div>   
                        </div>                     
                    </v-col>
                    <!-- <v-col cols="12" lg="7" class="mt-20px mt-md-56px mt-lg-0">
                        <card-primary v-for="(item, index) in project" :key="index" :class="index !== 0 ? 'mt-12px mt-md-24px' : ''" data-aos="fade-up" class="count-wrap">
                            <div class="pa-16px pa-md-20px px-lg-36px py-lg-24px">
                                <v-row align="center">
                                    <v-col cols="12" md="">
                                        <v-img :src="item.icon" max-width="48" :aspect-ratio="1 / 1" contain class="mb-8px mb-md-16px" />
                                        <u-txt-medium class="txt--lg line-height-14 txt--dark">
                                            <p>{{item.title}}</p>
                                            <p class="txt--sm grey--text text--lighten-3 font-weight-regular mt-8px mt-md-16px">* 판사 재직 시절</p>
                                        </u-txt-medium>
                                    </v-col>
                                    <v-col cols="12" md="auto">
                                        <div class="min-w-md-240px">
                                            <v-row align="center" justify="end" class="row--xxs">
                                                <v-col cols="auto">
                                                    <u-tit-default class="tit--lg font-primary font-weight-bold count">{{item.count}}</u-tit-default>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <u-txt-default class="txt--lg line-height-1">건</u-txt-default>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </card-primary>
                    </v-col> -->
                </v-row>
            </template>
        </sub-image-title>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import SubImageTitle from "@/sets/styles/sub/sub-image-title.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        SubImageTitle,
        CardPrimary,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            project: [
                {
                    title: "민사·행정 판결문, 결정문 평균작성건수",
                    count: "3,368",
                    icon: "/images/sub/firm/about/about-icon2.svg",
                    animated: false,
                },
                {
                    title: "검사제출서면(수사, 공판, 영장, 보석) 평균검토건수",
                    count: "19,520",
                    icon: "/images/sub/firm/about/about-icon3.svg",
                    animated: false,
                },
                {
                    title: "형사(형사항소) 판결문, 결정문 평균작성건수",
                    count: "1,920",
                    icon: "/images/sub/firm/about/about-icon4.svg",
                    animated: false,
                },
                {
                    title: "변호인제출서면 평균검토건수",
                    count: "26,440",
                    icon: "/images/sub/firm/about/about-icon5.svg",
                    animated: false,
                },
            ],
        };
    },
    methods: {
        handleScroll() {
            const cards = document.querySelectorAll('.count-wrap');
            const scrollOffset = 200;
            const windowHeight = window.innerHeight - scrollOffset;

            cards.forEach((card, index) => {
                const cardRect = card.getBoundingClientRect();
                const cardTop = cardRect.top;

                if (cardTop < windowHeight && !this.project[index].animated) {
                    const countElement = card.querySelector('.count');
                    const countValue = parseInt(this.project[index].count.replace(',', ''));
                    let startCount = 0;
                    const animationDuration = 4000;

                    const updateCount = () => {
                        if (startCount < countValue) {
                            startCount += Math.ceil(countValue / (animationDuration / 60));
                            countElement.textContent = startCount.toLocaleString();
                            requestAnimationFrame(updateCount);
                        } else {
                            countElement.textContent = countValue.toLocaleString();
                            this.project[index].animated = true;
                        }
                    };

                    updateCount();
                }
            });
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>

<style lang="scss" scoped></style>
