<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-svg icon-search" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <g>
            <path
                 fill-rule="evenodd" clip-rule="evenodd" d="M10.7437 0C4.81016 0 0 4.81016 0 10.7437C0 16.6773 4.81016 21.4875 10.7437 21.4875C13.3969 21.4875 15.8255 20.5257 17.6999 18.9318L22.7685 24.0004L24.0004 22.7685L18.9318 17.6999C20.5257 15.8255 21.4875 13.3969 21.4875 10.7437C21.4875 4.81016 16.6773 0 10.7437 0ZM1.74223 10.7437C1.74223 5.77237 5.77237 1.74223 10.7437 1.74223C15.7151 1.74223 19.7453 5.77237 19.7453 10.7437C19.7453 15.7151 15.7151 19.7453 10.7437 19.7453C5.77237 19.7453 1.74223 15.7151 1.74223 10.7437Z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-xx-small {
        height: 12px;
    }
    &-x-small {
        height: 14px;
    }
    &-small {
        height: 16px;
    }
    &-default {
        height: 20px;
    }
    &-large {
        height: 22px;
    }
    &-x-large {
        height: 24px;
    }
    &-xx-large {
        height: 26px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .icon--size {
        &-default {
            height: 24px;
        }
        &-large {
            height: 26px;
        }
        &-x-large {
            height: 28px;
        }
        &-xx-large {
            height: 30px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>
