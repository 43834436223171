<template>
    <div>

        <board-filter data-aos="fade-up" class="mb-30px mb-md-68px"
        @searchKey="(searchKey) => { filter.searchKey = searchKey }" 
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="search"
        />

        <v-row data-aos="fade-up">

            <!-- S: recent -->
            <v-col v-for="board in boards" :key="board._id" cols="6" md="4">
                <v-card :to="`/media/view/${board._id}`" tile flat color="transparent" class="recent-item v-card--none-active-bg plus-btn-card">
                    <div class="recent-item__thumb-wrap">
                        <v-img :src="board.thumb" max-width="464" :aspect-ratio="1 / 1" class="recent-item__thumb w-100" />
                    </div>
                    <div class="py-18px py-md-36px">
                        <u-txt-default class="txt--sm txt--light line-height-14">
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <p>미디어</p>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <p>{{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}</p>
                                </v-col>
                            </v-row>
                        </u-txt-default>
                        <u-tit-medium class="tit--sm line-height-14 text-truncate-2 mt-8px mt-md-16px">
                            {{ board?.subject }}
                        </u-tit-medium>
                        <icon-plus class="mt-20px mt-md-60px"/>
                    </div>
                </v-card>
            </v-col>
            <!-- E: recent -->

        </v-row>

    </div>
</template>

<script>
import api from "@/api";
import BoardFilter from "@/components/client/board/board-filter.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    components: {
        BoardFilter,
        IconPlus,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            limit: 0,
            boards: [],
            filter: {
                searchKey: "subject",
                searchValue: null,
                code: "media",
            }
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search()
        },
        async search(routable = false){
            if(routable){
                this.router.push({
                    query: { ...this.filter, page: this.page }
                });
            }
        
            var { summary, boards } = await api.v1.boards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });
        
            this.boards = boards;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    },
}
</script>

<style lang="scss" scoped>
.recent-item{
    &__thumb{
        &-wrap{
            overflow: hidden;
            position: relative;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(13, 25, 50, .4);
                opacity: 0;
                visibility: hidden;
                transition: 0.4s ease-out;
            }
        }
        transform: scale(1);
        transition: 0.4s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .recent-item:hover{
        .recent-item__thumb{
            &-wrap{
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
            transform: scale(1.2);
        }
    }
    }
@media (min-width:1200px){
}

</style>