<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="Advisory committee" bg>
                <template #titHead>A</template>
                <template #tit>dvisory committee</template>
            </sub-visual>
        </template>

        <page-section class="page-section--first page-section--last">
            <v-row data-aos="fade-up" class="row--lg my-n20px my-md-n40px">
                <v-col v-for="(item, key) in lawyers" :key="key" cols="6" md="4" lg="3" class="py-20px py-md-40px">
                    <member-item :to="`/advisory/${item._id}`" :image="item.listImage">
                        <template #txt>
                            <span v-if="item.job">{{item.job}}</span>
                            <span v-else>변호사</span>
                        </template>
                        {{item.name}}
                    </member-item>              
                </v-col>
            </v-row>
        </page-section>
    </app-primary>
</template>

<script>
import api from "@/api"
import { MEMBER_TYPES } from "@/assets/variables";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

import MemberItem from "@/components/client/member/member-item.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,

        MemberItem,
    },
    data() {
        return { 
            lawyers: []
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        async search(){
            try{
                let { summary, lawyers } = await api.v1.lawyers.gets({
                    params: { type: MEMBER_TYPES.ADVISORY.value }
                });

                this.lawyers = lawyers;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
};
</script>
