<template>
    <v-dialog v-model="shows" v-bind="$attrs">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-toolbar color="grey">
                <v-spacer></v-spacer>
                <v-toolbar-title class="text-center white--text"><slot name="tit" /></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon class="v-size--xx-large rounded-0" @click.stop="close"><u-icon color="white--text">close</u-icon></v-btn>
            </v-toolbar>
            <div class="pa-20px pa-md-60px">
                <slot />
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {},
    components: {
        UIcon,
        Tit,
    },
    data: () => {
        return {
            shows: false,
        };
    },
    mounted() {},
    methods: {
        close() {
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
