import { render, staticRenderFns } from "./user-items.vue?vue&type=template&id=7dc04568&scoped=true"
import script from "./user-items.vue?vue&type=script&lang=js"
export * from "./user-items.vue?vue&type=script&lang=js"
import style0 from "./user-items.vue?vue&type=style&index=0&id=7dc04568&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dc04568",
  null
  
)

export default component.exports