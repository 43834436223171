import { render, staticRenderFns } from "./u-textarea-solo-tile.vue?vue&type=template&id=c6b43444&scoped=true"
import script from "./u-textarea-solo-tile.vue?vue&type=script&lang=js"
export * from "./u-textarea-solo-tile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b43444",
  null
  
)

export default component.exports