var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Lawyer",
            "bg": ""
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("L")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("awyer")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--lg my-n20px my-md-n40px",
    attrs: {
      "data-aos": "fade-up"
    }
  }, _vm._l(_vm.lawyers, function (item, key) {
    return _c('v-col', {
      key: key,
      staticClass: "py-20px py-md-40px",
      attrs: {
        "cols": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('member-item', {
      attrs: {
        "to": `/lawyer/${item._id}`,
        "image": item.listImage
      },
      scopedSlots: _vm._u([{
        key: "txt",
        fn: function () {
          return [item.job ? _c('span', [_vm._v(_vm._s(item.job))]) : _c('span', [_vm._v("변호사")])];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }