<template>
    <user-items icon text class="user-items--icon-text">
        <slot />
    </user-items>
</template>

<script>
import UserItems from "@/components/publish/styles/headers/user-items.vue";

export default {
    props: {
        icon: { type: Boolean },
        text: { type: Boolean },
    },
    components: {
        UserItems,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
