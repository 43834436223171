<template>
    <u-user-items-icon>
        <slot />
    </u-user-items-icon>
</template>

<script>
import UUserItemsIcon from "@/components/publish/styles/headers/u-user-items-icon.vue";

export default {
    props: {},
    components: {
        UUserItemsIcon,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
