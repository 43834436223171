var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Success Story"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("S")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("uccess Story")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('story-view', {
    attrs: {
      "data-aos": "fade-up",
      "board": _vm.board
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }