<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <!-- S: Radio -->
        <h2>Radio</h2>
        <p><u>radio-{parents}</u></p>
        <br />
        <v-radio-group v-bind="{ ...attrs_controls_common }" row>
            <radio-primary label="Radio" value="1" />
            <radio-secondary label="Radio" value="1" />
            <radio-tertiary label="Radio" value="1" />
            <radio-quarternary label="Radio" value="1" />
        </v-radio-group>
        <!-- E: Radio -->
        <br />

        <!-- S: Checkbox -->
        <h2>Checkbox</h2>
        <p><u>checkbox-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <checkbox-primary label="Checkbox" value="1" />
            </v-col>
            <v-col cols="auto">
                <checkbox-secondary label="Checkbox" value="1" />
            </v-col>
            <v-col cols="auto">
                <checkbox-tertiary label="Checkbox" value="1" />
            </v-col>
            <v-col cols="auto">
                <checkbox-quarternary label="Checkbox" value="1" />
            </v-col>
        </v-row>
        <!-- E: Checkbox -->
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Density -->
        <h1>Density</h1>
        <h3>{density} : compact, comfortable, (기본-default)</h3>
        <p><u>class="v-input--density-{density}"</u></p>
        <br />
        <!-- S: Radio -->
        <h2>Radio</h2>
        <v-radio-group v-bind="{ ...attrs_controls_common }" row>
            <v-radio label="Radio" value="1" class="v-input--density-compact" />
            <v-radio label="Radio" value="2" class="v-input--density-comfortable" />
            <v-radio label="Radio" value="3" />
        </v-radio-group>
        <!-- E: Radio -->
        <br />

        <!-- S: Checkbox -->
        <h2>Checkbox</h2>
        <v-row align="center">
            <v-col cols="auto">
                <v-checkbox v-bind="{ ...attrs_controls_common }" label="Checkbox" value="1" class="v-input--density-compact" />
            </v-col>
            <v-col cols="auto">
                <v-checkbox v-bind="{ ...attrs_controls_common }" label="Checkbox" value="1" class="v-input--density-comfortable" />
            </v-col>
            <v-col cols="auto">
                <v-checkbox v-bind="{ ...attrs_controls_common }" label="Checkbox" value="1"></v-checkbox>
            </v-col>
        </v-row>
        <!-- E: Checkbox -->
        <!-- // Density -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>color="{color}"</u></p>
        <br />
        <!-- S: Radio -->
        <h2>Radio</h2>
        <v-radio-group v-bind="{ ...attrs_controls_common }" row>
            <v-radio label="Default(Primary)" value="1" />
            <v-radio color="secondary" label="Secondary" value="1" />
            <v-radio color="accent" label="Accent" value="1" />
            <v-radio color="grey" label="Grey" value="1" />
        </v-radio-group>
        <!-- E: Radio -->
        <br />

        <!-- S: Checkbox -->
        <h2>Checkbox</h2>
        <v-row align="center">
            <v-col cols="auto">
                <v-checkbox v-bind="{ ...attrs_controls_common }" label="Default(Primary)" value="1" />
            </v-col>
            <v-col cols="auto">
                <v-checkbox v-bind="{ ...attrs_controls_common }" color="secondary" label="Secondary" value="1" />
            </v-col>
            <v-col cols="auto">
                <v-checkbox v-bind="{ ...attrs_controls_common }" color="accent" label="Accent" value="1" />
            </v-col>
            <v-col cols="auto">
                <v-checkbox v-bind="{ ...attrs_controls_common }" color="grey" label="Grey" value="1" />
            </v-col>
        </v-row>
        <!-- E: Checkbox -->
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Radio -->
        <h2>Radio</h2>
        <v-row align="center">
            <v-col cols="auto">
                <h3>Disabled</h3>
                <p><u>disabled</u></p>
                <br />
                <v-radio-group v-bind="{ ...attrs_controls_common }" row>
                    <v-radio disabled label="Disabled" value="1" />
                </v-radio-group>
            </v-col>
        </v-row>
        <!-- E: Radio -->
        <br />

        <!-- S: Checkbox -->
        <h2>Checkbox</h2>
        <v-row align="center">
            <v-col cols="auto">
                <h3>Disabled</h3>
                <p><u>disabled</u></p>
                <br />
                <v-row align="center">
                    <v-col cols="auto">
                        <v-checkbox v-bind="{ ...attrs_controls_common }" disabled label="Disabled" value="1" />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="auto">
                <h3>Indeterminate</h3>
                <p><u>indeterminate</u></p>
                <br />
                <v-row align="center">
                    <v-col cols="auto">
                        <v-checkbox v-bind="{ ...attrs_controls_common }" indeterminate label="Indeterminate" value="1" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- E: Checkbox -->
        <!-- // State -->
    </div>
</template>

<script>
import { attrs_controls_common } from "@/assets/variables";

import RadioPrimary from "@/components/publish/parents/forms/radio-primary.vue";
import RadioSecondary from "@/components/publish/parents/forms/radio-secondary.vue";
import RadioTertiary from "@/components/publish/parents/forms/radio-tertiary.vue";
import RadioQuarternary from "@/components/publish/parents/forms/radio-quarternary.vue";
import CheckboxPrimary from "@/components/publish/parents/forms/checkbox-primary.vue";
import CheckboxSecondary from "@/components/publish/parents/forms/checkbox-secondary.vue";
import CheckboxTertiary from "@/components/publish/parents/forms/checkbox-tertiary.vue";
import CheckboxQuarternary from "@/components/publish/parents/forms/checkbox-quarternary.vue";

export default {
    props: {},
    components: {
        RadioPrimary,
        RadioSecondary,
        RadioTertiary,
        RadioQuarternary,
        CheckboxPrimary,
        CheckboxSecondary,
        CheckboxTertiary,
        CheckboxQuarternary,
    },
    data() {
        return {
            attrs_controls_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
