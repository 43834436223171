<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="The firm" tabActive="대표변호사 인사말">
                <template #titHead>T</template>
                <template #tit>he Firm</template>
            </sub-visual>
        </template>
        <page-section class="page-section--first">
            <div class="intro-contents--1">
                <v-row no-gutters>
                    <v-col cols="12" md="6">
                        <v-img data-aos="fade-up" src="/images/sub/firm/intro/intro-img.png" max-width="708" :aspect-ratio="708 / 910" class="w-100 mx-auto" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <div class="pl-md-24px pl-lg-84px" style="padding-top: var(--page-section-padding-y)">
                            <u-txt-default data-aos="fade-up" data-aos-delay="200" class="txt--lg break-keep">
                                <p>
                                    2010년 개설한 ‘판사의 마음’ 블로그에 내가 처음 글을 쓰기 시작한 것은 <br class="d-none d-xl-block">
                                    나의 법정에서 재판을 받는 내 재판의 당사자들에게 법대에 앉은 판사의 <br class="d-none d-xl-block">
                                    마음을 담담히 말해주고 싶어서였다.
                                </p>
                                <p>
                                    판사가 법정에서는 차마 다 말로 하지 못했던 말들, <br class="d-none d-xl-block">
                                    판사가 판결문에는 빼곡히 담지 못한 마음들.
                                </p>
                            </u-txt-default>
                        </div>
                    </v-col>
                </v-row>
                <h2 data-aos="fade-up" data-aos-delay="400" class="intro-contents--1__object">
                    Moon Yujin
                </h2>
            </div>
        </page-section>
        <page-section class="pt-0">
            <v-row align="center">
                <v-col cols="12" md="6">
                    <div data-aos="fade-up" class="pr-lg-96px">
                        <u-txt-default class="txt--lg break-keep">
                            <p>
                                당사자가 받는 판결문은 몇 장에 불과할지라도, 그 속에 담긴 판사의 마음은
                                항상 수천 장이었다. 처음 판사가 받아 들고 검토하게 되는 소장, 답변서,
                                준비서면, 고소장, 공소장, 변호인 의견서들.
                            </p>
                            <p>
                                여기부터 판사의 고심은 그 머나먼 여정이 시작된다.
                            </p>
                            <p>
                                한 사건의 판결을 선고하기까지. 아니, 정확히는 판결을 선고하고 나서가 끝이 아니다. 오히려 판결선고 당시에는 결론에 확신이 있었는데, 선고 이후 ‘그때 내 판단은 맞는 것이었을까’라는 의문이 주심판사의 머릿속에서 떠나지 않는
                                사건도 있다. 그렇게 선고 이후에도 판단에 대한 스스로의 의문이 사라지는 알 수 없는 미래의 순간까지, 주심판사의 고심은 이어진다.
                            </p>
                            <p>첫 임관 후 초임 시절 미숙하게 써 내려갔던 판결문의 결론, 구성, 고심했던 문구, 단어.</p>
                            <p>판사라면, 평생 자신의 마음 속에 남아 있지 않을까.</p>
                        </u-txt-default>
                    </div>
                </v-col>
                <v-col data-aos="fade-up" data-aos-delay="200" cols="12" md="6">
                    <v-img src="/images/sub/firm/intro/intro-img2.svg" max-width="708" :aspect-ratio="1 / 1" class="w-100 mx-auto" />
                </v-col>
            </v-row>
        </page-section>
        <page-section class="pt-0">
            <template #pageSectionHead>
                <v-img data-aos="fade-up" src="/images/sub/firm/intro/intro-img3.jpg" max-width="1920" :aspect-ratio="1920 / 500" class="w-100 mx-auto" />
            </template>
            <div class="mt-40px mt-md-80px">
                <u-txt-default data-aos="fade-up" class="txt--lg break-keep">
                    <p>
                        변호사들의 수많은 준비서면, 의견서를 받아 들고 판사가 어떤 생각을 하기 시작하는지, 그 서면을 검토하는 과정, 첨예하게 다투는 주장 중 어떤 주장에 마음이 쏠리기 시작하는지, 그
                        주장을 뒷받침하는 증거 중 어떤 증거의 가치를 더 높다고 판단하게 되는지, 법정에서 기일을 지정하는 방식, 기일을 갑자기 연기하게 되는 사유, 어떤 증인에 대하여는 증인신청을 받아 주는 반면에 어떤 증인에 대하여는 신청을 기각하게 되는지, 또 최종 결론을 내는 재판부 합의과정은 언제 거치게 되는지, 판결문은 언제 쓰는지, 판결문을 쓰고 난 이후 결론을 고치지는
                        않는지. 
                    </p>
                    <p>
                        결국 이러한 과정들을 거쳐 판결 선고기일, 판사가 법대에서 구술로 선고를 하는 마지막 순간까지.
                    </p>
                    <p>
                        주심판사는 출근하는 길에도, 밥을 먹을 때도, 잠자리에 들어서도, 쉬지 않고 사건의 사실관계를 상상하고, 법리를 고심하며, 결론을 고민한다. 자다가 또는 씻다가 생각난 판결문 문구를
                        급하게 핸드폰 메모장에 적는 일은 판사라면 숱한 일이다. 이런 이성적 판단에 더해 내 판결의 결론이 당사자와 피해자의 인생에 미칠 영향, 나아가 내 판결이 선례가 되어 파장을 미칠지도 모르는 사회적 영향까지 생각하지 않을 수 없다. 판사 시절부터 작성해온 나만의 “판사의 마음” 블로그는 그야말로 이런 고심에 고심을 거듭하여 사건의 결론을 내리고 판결문 단어 하나도 고르고 골라 쓰는 판사의 마음을 진솔하게 써 나갔던, 나의 애정이 담긴 그것이었다.
                    </p>
                    <p>
                        그러던 블로그가 어느새 13년, 사람으로 치면 중학생이 되었고, 그 세월이 흐르는 동안 나는 2022년. 법복을 벗고 새로운 삶을 시작하였다.
                    </p>
                </u-txt-default>
            </div>
        </page-section>
        <page-section class="pt-0 page-section--last">
            <div class="intro-contents--4">
                <v-row>
                    <v-col cols="10" md="8" offset="2" offset-md="4">
                        <div data-aos="fade-left" data-aos-delay="400" class="intro-contents--4__object"></div>
                    </v-col>
                </v-row>
                <div class="intro-contents--4__foot">
                    <v-row align="end">
                        <v-col cols="12" md="6">
                            <v-row>                                
                                <v-col cols="4">
                                    <v-img data-aos="fade-up" src="/images/sub/firm/intro/intro-img4.jpg" max-width="220" :aspect-ratio="220 / 330" class="w-100" />
                                </v-col>
                                <v-col cols="8">
                                    <v-img data-aos="fade-up" data-aos-delay="200" src="/images/sub/firm/intro/intro-img5.jpg" max-width="464" :aspect-ratio="464 / 696" class="w-100" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6" class="mt-40px mt-md-0">
                            <div data-aos="fade-up" data-aos-delay="200" class="pl-lg-44px">
                                <u-txt-default class="txt--lg break-keep">
                                    <p>
                                        판사의 마음으로 오랜 시간 생활해 온 나에게 누군가가 변호사가 된 나의 마음이 얼마나 달라졌느냐고 묻는다면,
                                    </p>
                                    <p>
                                        이렇게 대답해주고 싶다.
                                    </p>
                                    <p>
                                        내가 가졌던 판사의 마음은 다른 그 누구도 아닌, “나의 판사의 마음”이었고, <br class="d-none d-xl-block">
                                        내 마음 속의 “나만의 판사의 마음”이 얼마나 살아있는지 나 또한 <br class="d-none d-xl-block">
                                        궁금하다고,
                                    </p>
                                </u-txt-default>
                                <u-tit-default class="tit--sm font-weight-bold line-height-14 mt-16px mt-md-32px">
                                    그런 마음으로 나의 사무실 “판사의 마음” <br>
                                    <span class="primary--text text--lighten-3">즉, 판심을 연다.</span>
                                </u-tit-default>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </page-section>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.intro-contents{
    &--1{
        position: relative;
        &__object{
            font-weight: 600;
            font-size: 6.6rem;
            font-family: var(--font-secondary);
            position: absolute;
            top: 50%;
            right: 0;
            line-height: .8;
            color: var(--v-grey-lighten5);
            text-align: right;
            white-space: nowrap;
        }
    }
    &--4{
        &__object{
            width: 100vw;
            height: calc(var(--page-section-padding-y) * 2);
            background-color: var(--v-grey-lighten5);
        }
        &__foot{
            margin-top: calc((var(--page-section-padding-y) * -1) - (var(--grid-gutter) * 2));
        }
    }
}
@media (min-width:576px){
    .intro-contents{
        &--1{
            &__object{
                font-size: 8rem;
            }
        }
    }
}
@media (min-width:768px){
    .intro-contents{
        &--1{
            &__object{
                top: unset;
                bottom: 0;
                font-size: 12rem;
            }
        }
    }
}
@media (min-width:1024px){
    .intro-contents{
        &--1{
            &__object{
                font-size: 16rem;
            }
        }
    }
}
@media (min-width:1200px){
    .intro-contents{
        &--1{
            position: relative;
            &__object{
                font-size: 24rem !important;                
                bottom: var(--page-section-padding-y);
            }
        }
    }
}
@media (min-width:1440px){
    .intro-contents{
        &--1{
            position: relative;
            &__object{
                bottom: var(--page-section-padding-y);
            }
        }
    }
}

</style>
