var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', _vm._b({
    attrs: {
      "location": "end",
      "transition": "slide-x-transition",
      "content-class": "rounded-0"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'v-menu', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }