<template>
    <u-user-items-icon-text class="user-items--light">
        <slot />
    </u-user-items-icon-text>
</template>

<script>
import UUserItemsIconText from "@/components/publish/styles/headers/u-user-items-icon-text.vue";

export default {
    props: {},
    components: {
        UUserItemsIconText,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
