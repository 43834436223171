var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Basic")]), _vm._m(0), _c('br'), _c('u-gnb-basic', {
    attrs: {
      "gnbName": "gnb"
    }
  }), _c('br'), _c('h2', [_vm._v("Filled")]), _vm._m(1), _c('br'), _c('u-gnb-filled', {
    attrs: {
      "gnbName": "gnb"
    }
  }), _c('br'), _c('h2', [_vm._v("Topline")]), _vm._m(2), _c('br'), _c('u-gnb-topline', {
    attrs: {
      "gnbName": "gnb"
    }
  }), _c('br'), _c('h2', [_vm._v("Underline")]), _vm._m(3), _c('br'), _c('u-gnb-underline', {
    attrs: {
      "gnbName": "gnb"
    }
  }), _c('v-divider', {
    staticClass: "my-30px"
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-gnb-basic")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-gnb-filled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-gnb-topline")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-gnb-underline")])]);

}]

export { render, staticRenderFns }