<template>
    <div>
        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Basic -->
        <h2>Basic</h2>
        <p><u>u-gnb-basic</u></p>
        <br />
        <u-gnb-basic gnbName="gnb"></u-gnb-basic>
        <!-- E: Basic -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-gnb-filled</u></p>
        <br />
        <u-gnb-filled gnbName="gnb"></u-gnb-filled>
        <!-- E: Filled -->
        <br />

        <!-- S: Topline -->
        <h2>Topline</h2>
        <p><u>u-gnb-topline</u></p>
        <br />
        <u-gnb-topline gnbName="gnb"></u-gnb-topline>
        <!-- E: Topline -->
        <br />

        <!-- S: Underline -->
        <h2>Underline</h2>
        <p><u>u-gnb-underline</u></p>
        <br />
        <u-gnb-underline gnbName="gnb"></u-gnb-underline>
        <!-- E: Underline -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <!-- <h1>Color</h1>
        <p><u>gnb--light</u></p>
        <br />
        <div class="grey pa-20px">
            <u-gnb-icon class="gnb--light"></u-gnb-icon>
            <u-gnb-text class="gnb--light"></u-gnb-text>
            <u-gnb-icon-text class="gnb--light"></u-gnb-icon-text>
        </div> -->
        <!-- // Color -->
    </div>
</template>

<script>
import UGnbBasic from "@/components/publish/styles/headers/u-gnb-basic.vue";
import UGnbFilled from "@/components/publish/styles/headers/u-gnb-filled.vue";
import UGnbTopline from "@/components/publish/styles/headers/u-gnb-topline.vue";
import UGnbUnderline from "@/components/publish/styles/headers/u-gnb-underline.vue";

export default {
    props: {},
    components: {
        UGnbBasic,
        UGnbFilled,
        UGnbTopline,
        UGnbUnderline,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
