<template>
    <div v-if="board">
        <div class="board-view">
            <div class="board-view__row board-view__row--title">
                {{ board?.subject }}
            </div>
            <div class="board-view__row board-view__row--info">
                <v-row>
                    <v-col cols="auto">
                        작성자 : 관리자
                    </v-col>
                    <v-col cols="auto">
                        작성일 : {{ $dayjs(board.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                    </v-col>
                    <v-col cols="auto">
                        조회수 : {{ board.viewCount.format() }}
                    </v-col>
                </v-row>
            </div>
            <div class="board-view__row board-view__row">
                <video v-if="board.video" :src="board.video" controls muted playsinline width="500px"/>
                <div v-html="board.content" class="board-view__row board-view__row"></div>
            </div>
        </div>
        <div class="mt-24px">
            <v-row justify="end">
                <v-col cols="auto">
                    <btn-secondary small @click="$router.go(-1)">리스트</btn-secondary>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import BtnSecondary from "@/components/publish/parents/buttons/btn-secondary.vue";

export default {
    props: {
        board: { type: Object, default: () => {} }
    },
    components: {
        BtnSecondary,
    },
}
</script>

<style lang="scss" scoped></style>