var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('select-primary', {
    staticClass: "v-input--density-compact",
    attrs: {
      "items": _vm.items,
      "label": "Select Primary"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('select-secondary', {
    staticClass: "v-input--density-default",
    attrs: {
      "items": _vm.items,
      "placeholder": "Select Secondary"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('select-tertiary', {
    attrs: {
      "items": _vm.items,
      "label": "Select Tertiary"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('select-quarternary', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Select Quarternary"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Underlined")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-underlined', {
    attrs: {
      "items": _vm.items,
      "label": "Underlined Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-underlined', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Underlined Placeholder"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-tile', {
    attrs: {
      "items": _vm.items,
      "label": "Outlined Tile Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-rounded', {
    attrs: {
      "items": _vm.items,
      "label": "Outlined Rounded Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-pill', {
    attrs: {
      "items": _vm.items,
      "label": "Outlined Pill Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-tile', {
    staticClass: "v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "label": "Outlined Tile Label Inner"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-rounded', {
    staticClass: "v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "label": "Outlined Rounded Label Inner"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-pill', {
    staticClass: "v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "label": "Outlined Pill Label Inner"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-tile', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Outlined Tile Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-rounded', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Outlined Rounded Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-outlined-pill', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Outlined Pill Placeholder"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Filled")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-filled-tile', {
    attrs: {
      "items": _vm.items,
      "label": "Filled Tile Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-filled-rounded', {
    attrs: {
      "items": _vm.items,
      "label": "Filled Rounded Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-filled-pill', {
    attrs: {
      "items": _vm.items,
      "label": "Filled Pill Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-filled-tile', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Filled Tile Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-filled-rounded', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Filled Rounded Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-filled-pill', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Filled Pill Placeholder"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Solo")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-solo-tile', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Solo Tile Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-solo-rounded', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Solo Rounded Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-select-solo-pill', {
    attrs: {
      "items": _vm.items,
      "placeholder": "Solo Pill Placeholder"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Density")]), _c('h3', [_vm._v("{density} : compact, (기본-comfortable), default")]), _vm._m(5), _c('br'), _c('h2', [_vm._v("Underlined")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "items": _vm.items,
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "items": _vm.items,
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "items": _vm.items,
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "items": _vm.items,
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Label Inner")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Filled")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "items": _vm.items,
      "filled": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "filled": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "items": _vm.items,
      "filled": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "items": _vm.items,
      "filled": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "filled": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "items": _vm.items,
      "filled": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Solo")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "items": _vm.items,
      "solo": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "solo": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "items": _vm.items,
      "solo": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Width")]), _vm._m(6), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "min-w-140px min-w-md-200px",
    attrs: {
      "items": _vm.items,
      "label": "Custom Width"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "label": "default block"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-compact v-input--width-fixed",
    attrs: {
      "items": _vm.items,
      "label": "Width Fixed"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--width-fixed",
    attrs: {
      "items": _vm.items,
      "label": "Width Fixed"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-input--density-default v-input--width-fixed",
    attrs: {
      "items": _vm.items,
      "label": "Width Fixed"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("State")]), _c('h2', [_vm._v("Disabled")]), _vm._m(8), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "disabled": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "disabled": "",
      "placeholder": "placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Readonly")]), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "readonly": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "readonly": "",
      "placeholder": "placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Variant")]), _c('h3', [_vm._v("{variant} : (기본-underlined), outlined, label-inner(outlined), filled, solo")]), _c('p', [_vm._v("v-text-field--label-inner은 클래스로 입력해주세요. label-inner은 outlined속성과 같이 사용합니다.")]), _c('p', [_vm._v("solo는 label 스타일이 없습니다.")]), _vm._m(10), _vm._m(11), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "label": "Label Inner"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "filled": "",
      "label": "Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "filled": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "solo": "",
      "placeholder": "Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Rounded")]), _c('h3', [_vm._v("{rounded} : (기본), pill, 0")]), _vm._m(12), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "outlined": "",
      "items": _vm.items,
      "label": "Default"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "rounded-pill",
    attrs: {
      "outlined": "",
      "items": _vm.items,
      "label": "Pill"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "rounded-0",
    attrs: {
      "outlined": "",
      "items": _vm.items,
      "label": "Tile"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "filled": "",
      "items": _vm.items,
      "label": "Default"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "rounded-pill",
    attrs: {
      "filled": "",
      "items": _vm.items,
      "label": "Pill"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "rounded-0",
    attrs: {
      "filled": "",
      "items": _vm.items,
      "label": "Tile"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "items": _vm.items,
      "label": "Default Label Inner"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "rounded-pill v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "items": _vm.items,
      "label": "Pill Label Inner"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "rounded-0 v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "items": _vm.items,
      "label": "Tile Label Inner"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Icon")]), _c('h3', [_vm._v("{direction} : prepend, prepend-inner, append, append-outer, clear-icon")]), _c('p', [_vm._v("vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=\"\"을 사용하지 않고 template v-slot으로 아이콘을 추가합니다.")]), _c('p', [_vm._v("이벤트가 있는 아이콘은 template v-slot으로 사용하면 이벤트가 작동되지 않아 {direction}=\"\"형태로 수정합니다. 이때 아이콘은 mdi아이콘만 사용할 수 있습니다.")]), _vm._m(13), _vm._m(14), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Prepend Icon"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Prepend Inner Icon"
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "placeholder": "Append Icon"
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "append-icon": "mdi-chevron-down",
      "placeholder": "Append Inner Icon"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "clearable": "",
      "placeholder": "Prepend Icon Clearable"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "clearable": "",
      "placeholder": "Prepend Inner Icon Clearable"
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "clearable": "",
      "placeholder": "Append Icon Clearable"
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "clearable": "",
      "append-icon": "mdi-chevron-down",
      "placeholder": "Append Inner Icon Clearable"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "clearable": "",
      "clear-icon": "mdi-delete",
      "placeholder": "Prepend Append Clearable Icon"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function () {
        return [_c('u-icon', [_vm._v("check")])];
      },
      proxy: true
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "outlined": "",
      "clearable": "",
      "append-icon": "mdi-chevron-down",
      "clear-icon": "mdi-delete",
      "placeholder": "Prepend Append Clearable Icon"
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Multiple")]), _vm._m(15), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "multiple": "",
      "label": "Multiple"
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Chips")]), _vm._m(16), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "chips": "",
      "multiple": "",
      "label": "Chips Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "chips": "",
      "multiple": "",
      "placeholder": "Chips Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "small-chips": "",
      "multiple": "",
      "label": "Small Chips Label"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "small-chips": "",
      "multiple": "",
      "placeholder": "Small Chips Placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "chips": "",
      "multiple": "",
      "label": "Chips Label Custom"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          attrs: {
            "x-small": "",
            "label": "",
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item))])];
      }
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "chips": "",
      "multiple": "",
      "placeholder": "Chips Placeholder Custom"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "x-small": "",
            "label": "",
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item.text))])];
      }
    }])
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Supporting Text")]), _c('h2', [_vm._v("Hint, Messages")]), _vm._m(17), _vm._m(18), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "hint": "Hint",
      "placeholder": "Hint"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "messages": "Messages",
      "placeholder": "Messages"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Persistence")]), _c('h3', [_vm._v("{persistent} : hint, clear, placeholder")]), _vm._m(19), _c('p', [_vm._v("persistent-hint - 항상 힌트가 보입니다.")]), _c('p', [_vm._v("persistent-clear - 값이 있을 때 입력 지우기 아이콘을 항상 표시합니다.")]), _c('p', [_vm._v("persistent-placeholder - 항상 placeholder를 표시하고 label이 자동으로 올라갑니다.")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "persistent-hint": "",
      "hint": "Hint",
      "placeholder": "persistent-hint"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "clearable": "",
      "persistent-clear": "",
      "placeholder": "persistent-clear"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.items,
      "persistent-placeholder": "",
      "label": "Label",
      "placeholder": "placeholder-placeholder"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_select_common, _vm.$attrs), false))], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("select-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-select-underlined")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-select-outlined-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-select-filled-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-select-solo-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-input--density-{density}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("w-{breakpoint}-{size}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-input--width-fixed")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("disabled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("readonly")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{variant}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-text-field--label-inner\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"rounded-{rounded}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("#{direction}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{direction}=\"\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("multiple")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("chips, small-chips")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("hint=\"\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("messages=\"\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("persistent-{persistent}")])]);

}]

export { render, staticRenderFns }