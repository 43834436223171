<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>swiper-pagination-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <swiper-pagination-primary />
            </v-col>
            <v-col cols="auto">
                <swiper-pagination-secondary />
            </v-col>
            <v-col cols="auto">
                <swiper-pagination-tertiary />
            </v-col>
            <v-col cols="auto">
                <swiper-pagination-quarternary />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <p>swiper-pagination-bullet는 템플릿 작업을 위해 넣은 놓은 것입니다.</p>
        <p>사용시 swiper-pagination-bullet는 지우고 u-swiper-pagination-{}만 사용해주세요.</p>
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-swiper-pagination-filled</u></p>
        <br />
        <u-swiper-pagination-filled />

        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-filled class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-filled>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-filled>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Ellipse -->
        <h2>Ellipse</h2>
        <p><u>u-swiper-pagination-ellipse</u></p>
        <br />
        <u-swiper-pagination-ellipse />

        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-ellipse class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-ellipse>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-ellipse>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-ellipse>
            </v-col>
        </v-row>
        <!-- E: Ellipse -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-swiper-pagination-outlined</u></p>
        <br />
        <u-swiper-pagination-outlined />

        <v-row align="center">
            <v-col cols="auto">
                <u-swiper-pagination-outlined class="swiper-pagination--sm">
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-outlined>
            </v-col>
            <v-col cols="auto">
                <u-swiper-pagination-outlined>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                </u-swiper-pagination-outlined>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />
        <!-- // Styles -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
import SwiperPaginationSecondary from "@/components/publish/parents/swipers/swiper-pagination-secondary.vue";
import SwiperPaginationTertiary from "@/components/publish/parents/swipers/swiper-pagination-tertiary.vue";
import SwiperPaginationQuarternary from "@/components/publish/parents/swipers/swiper-pagination-quarternary.vue";

import USwiperPaginationFilled from "@/components/publish/styles/swiper/u-swiper-pagination-filled.vue";
import USwiperPaginationEllipse from "@/components/publish/styles/swiper/u-swiper-pagination-ellipse.vue";
import USwiperPaginationOutlined from "@/components/publish/styles/swiper/u-swiper-pagination-outlined.vue";

export default {
    props: {},
    components: {
        GuideApp,

        SwiperPaginationPrimary,
        SwiperPaginationSecondary,
        SwiperPaginationTertiary,
        SwiperPaginationQuarternary,

        USwiperPaginationFilled,
        USwiperPaginationEllipse,
        USwiperPaginationOutlined,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// 템플릿 작업을 위해 넣어 놓은 스타일입니다.
.swiper-pagination {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
}
</style>
