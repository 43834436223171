var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('text-field-primary', {
    staticClass: "v-input--density-compact",
    attrs: {
      "label": "Text Field Primary"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('text-field-secondary', {
    staticClass: "v-input--density-default",
    attrs: {
      "placeholder": "Text Field Secondary"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('text-field-tertiary', {
    attrs: {
      "label": "Text Field Tertiary"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('text-field-quarternary', {
    attrs: {
      "placeholder": "Text Field Quarternary"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('h2', [_vm._v("Underlined")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-underlined', {
    attrs: {
      "label": "Underlined Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-underlined', {
    attrs: {
      "placeholder": "Underlined Placeholder"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(2), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-tile', {
    attrs: {
      "label": "Outlined Tile Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-rounded', {
    attrs: {
      "label": "Outlined Rounded Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-pill', {
    attrs: {
      "label": "Outlined Pill Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-tile', {
    staticClass: "v-text-field--label-inner",
    attrs: {
      "label": "Outlined Tile Label Inner"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-rounded', {
    staticClass: "v-text-field--label-inner",
    attrs: {
      "label": "Outlined Rounded Label Inner"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-pill', {
    staticClass: "v-text-field--label-inner",
    attrs: {
      "label": "Outlined Pill Label Inner"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-tile', {
    attrs: {
      "placeholder": "Outlined Tile Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-rounded', {
    attrs: {
      "placeholder": "Outlined Rounded Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-outlined-pill', {
    attrs: {
      "placeholder": "Outlined Pill Placeholder"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Filled")]), _vm._m(3), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-filled-tile', {
    attrs: {
      "label": "Filled Tile Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-filled-rounded', {
    attrs: {
      "label": "Filled Rounded Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-filled-pill', {
    attrs: {
      "label": "Filled Pill Label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-filled-tile', {
    attrs: {
      "placeholder": "Filled Tile Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-filled-rounded', {
    attrs: {
      "placeholder": "Filled Rounded Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-filled-pill', {
    attrs: {
      "placeholder": "Filled Pill Placeholder"
    }
  })], 1)], 1), _c('br'), _c('h2', [_vm._v("Solo")]), _vm._m(4), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-solo-tile', {
    attrs: {
      "placeholder": "Solo Tile Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-solo-rounded', {
    attrs: {
      "placeholder": "Solo Rounded Placeholder"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('u-text-field-solo-pill', {
    attrs: {
      "placeholder": "Solo Pill Placeholder"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Density")]), _c('h3', [_vm._v("{density} : compact, (기본-comfortable), default")]), _vm._m(5), _c('br'), _c('h2', [_vm._v("Underlined")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "outlined": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "outlined": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Label Inner")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Filled")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "filled": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "filled": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "filled": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "filled": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "filled": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "filled": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Solo")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "solo": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "solo": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default",
    attrs: {
      "solo": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Width")]), _vm._m(6), _vm._m(7), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "min-w-140px min-w-md-200px",
    attrs: {
      "label": "Custom Width"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "default block"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-compact v-input--width-fixed",
    attrs: {
      "label": "Width Fixed"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--width-fixed",
    attrs: {
      "label": "Width Fixed"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-input--density-default v-input--width-fixed",
    attrs: {
      "label": "Width Fixed"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("State")]), _c('h2', [_vm._v("Disabled")]), _vm._m(8), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "disabled": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "disabled": "",
      "placeholder": "placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Readonly")]), _vm._m(9), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "readonly": "",
      "label": "Label",
      "value": "readonly"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "readonly": "",
      "placeholder": "placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Variant")]), _c('h3', [_vm._v("{variant} : (기본-underlined), outlined, label-inner(outlined), filled, solo")]), _c('p', [_vm._v("v-text-field--label-inner은 클래스로 입력해주세요. label-inner은 outlined속성과 같이 사용합니다.")]), _c('p', [_vm._v("solo는 label 스타일이 없습니다.")]), _vm._m(10), _vm._m(11), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "label": "Label Inner"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "filled": "",
      "label": "Label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "filled": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "solo": "",
      "placeholder": "Placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Rounded")]), _c('h3', [_vm._v("{rounded} : (기본),pill, 0")]), _vm._m(12), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "label": "Default"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "rounded-pill",
    attrs: {
      "outlined": "",
      "label": "Pill"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "rounded-0",
    attrs: {
      "outlined": "",
      "label": "Tile"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "filled": "",
      "label": "Default"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "rounded-pill",
    attrs: {
      "filled": "",
      "label": "Pill"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "rounded-0",
    attrs: {
      "filled": "",
      "label": "Tile"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "label": "Default Label Inner"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "rounded-pill v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "label": "Pill Label Inner"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "2"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "rounded-0 v-text-field--label-inner",
    attrs: {
      "outlined": "",
      "label": "Tile Label Inner"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Icon")]), _c('h3', [_vm._v("{direction} : prepend, prepend-inner, append, append-outer, clear-icon")]), _c('p', [_vm._v("vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=\"\"을 사용하지 않고 template v-slot으로 아이콘을 추가합니다.")]), _c('p', [_vm._v("이벤트가 있는 아이콘은 template v-slot으로 사용하면 이벤트가 작동되지 않아 {direction}=\"\"형태로 수정합니다. 이때 아이콘은 mdi아이콘만 사용할 수 있습니다.")]), _vm._m(13), _vm._m(14), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "placeholder": "Prepend Icon"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "placeholder": "Prepend Inner Icon"
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "placeholder": "Append Outer Icon"
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "placeholder": "Append Icon"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "clearable": "",
      "placeholder": "Prepend Icon Clearable"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "clearable": "",
      "placeholder": "Prepend Inner Icon Clearable"
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "clearable": "",
      "placeholder": "Append Outer Icon Clearable"
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "clearable": "",
      "placeholder": "Append Icon Clearable"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "clearable": "",
      "clear-icon": "delete",
      "placeholder": "Prepend Append Clearable Icon"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function () {
        return [_c('u-icon', [_vm._v("check")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "clearable": "",
      "clear-icon": "delete",
      "placeholder": "Prepend Append Clearable Icon"
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_c('u-icon', [_vm._v("attach_file")])];
      },
      proxy: true
    }, {
      key: "append",
      fn: function () {
        return [_c('u-icon', [_vm._v("check")])];
      },
      proxy: true
    }])
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Supporting Text")]), _c('h2', [_vm._v("Hide details")]), _c('h3', [_vm._v("{hide-details} : auto, hide")]), _vm._m(15), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "hint": "Hint",
      "placeholder": "Hide Details Auto"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "hide-details": "hide",
      "hint": "Hint",
      "placeholder": "Hide Details Hide"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Hint, Messages")]), _vm._m(16), _vm._m(17), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "hint": "Hint",
      "placeholder": "Hint"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "messages": "Messages",
      "placeholder": "Messages"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1), _c('br'), _c('h2', [_vm._v("Persistence")]), _c('h3', [_vm._v("{persistent} : hint, counter, clear, placeholder")]), _vm._m(18), _c('p', [_vm._v("persistent-hint - 항상 힌트가 보입니다.")]), _c('p', [_vm._v("persistent-counter - 항상 입력 문자 길이 요소를 표시합니다.")]), _c('p', [_vm._v("persistent-clear - 값이 있을 때 입력 지우기 아이콘을 항상 표시합니다.")]), _c('p', [_vm._v("persistent-placeholder - 항상 placeholder를 표시하고 label이 자동으로 올라갑니다.")]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "persistent-hint": "",
      "hint": "Hint",
      "placeholder": "persistent-hint"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "counter": "",
      "persistent-counter": "",
      "placeholder": "persistent-counter"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "clearable": "",
      "persistent-clear": "",
      "placeholder": "persistent-clear"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "persistent-placeholder": "",
      "label": "Label",
      "placeholder": "placeholder-placeholder"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input_common, _vm.$attrs), false))], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("text-field-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-text-field-underlined")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-text-field-outlined-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-text-field-filled-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-text-field-solo-{}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-input--density-{density}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("w-{breakpoint}-{size}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("v-input--width-fixed")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("disabled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("readonly")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{variant}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-text-field--label-inner\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"rounded-{rounded}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("#{direction}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("{direction}=\"\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("hide-details=\"{hide-details}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("hint=\"\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("messages=\"\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("persistent-{persistent}")])]);

}]

export { render, staticRenderFns }