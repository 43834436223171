<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="Lawyer" bg>
                <template #titHead>L</template>
                <template #tit>awyer</template>
            </sub-visual>
        </template>
        <page-section class="page-section--first page-section--sm">
            <v-row>
                <v-col cols="12" md="8">
                    <div class="lawyer-view__image">
                        <div data-aos="fade-right" data-aos-delay="400" :data-aos-offset="0" class="lawyer-view__image__bg lawyer-view__image__bg--1"></div>
                        <v-img data-aos="fade-right" data-aos-delay="200" :data-aos-offset="0" src="/images/sub/lawyer/lawyer-bg.svg" max-width="952" :aspect-ratio="952 / 116" class="lawyer-view__image__bg lawyer-view__image__bg--2" />
                        <v-img data-aos="fade-right" :data-aos-offset="0" :src="this.lawyer?.viewImage" max-width="952" :aspects-ratio="952 / 696" class="w-100 mx-auto" />
                    </div>
                </v-col>
                <v-col cols="12" md="4" class="d-flex align-end mt-20px mt-md-0">
                    <div class="lawyer-view__title">
                        <v-row align="center" justify="end">
                            <v-col cols="auto">
                                <u-tit-default data-aos="fade-up" :data-aos-offset="0" class="tit--lg font-weight-bold line-height-14">{{this.lawyer?.name}}</u-tit-default>
                            </v-col>
                            <v-col>
                                <v-divider data-aos="fade-up" data-aos-delay="200" :data-aos-offset="0" class="grey lighten-3" />
                            </v-col>
                            <v-col cols="auto">
                                <u-txt-default data-aos="fade-up" data-aos-delay="400" :data-aos-offset="0" class="txt--lg txt--dark line-height-14">
                                    <span v-if="this.lawyer?.job">{{this.lawyer?.job}}</span>
                                    <span v-else>변호사</span>
                                </u-txt-default>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </page-section>
        <page-section v-if="this.lawyer?.career" class="page-section--last pt-0">
            
            <!-- <info-row v-for="(info, index) in this.lawyer.info" :key="index" :title="info.title" :topLine="index == 0">
                <info-detail v-for="(detail, index) in info.infoDetail" :key="index" :class="index !== 0 ? 'mt-4px' : ''">
                    {{detail}}
                </info-detail>
            </info-row> -->

            <info-row title="약력" topLine>
                <template v-for="(career, index) in this.lawyer?.career">
                    <info-detail data-aos="fade-up" data-aos-delay="100" :dotNone="!career.txt" :key="index" :class="index !== 0 ? 'mt-4px' : ''">
                        {{career.txt}}
                    </info-detail>
                </template>
            </info-row>

        </page-section>
    </app-primary>
</template>

<script>
import api from "@/api";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import InfoRow from "@/sets/styles/info-row.vue";
import InfoDetail from "@/sets/styles/info-detail.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {
        lawyerId: String,
    },
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        InfoRow,
        InfoDetail,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return { 
            lawyer: null,
        }
    },
    mounted() {
        this.search();
    },
    methods:{
        async search(){
            try{
                let { lawyer } = await api.v1.lawyers.get({ _id: this.$route.params._lawyer });

                this.lawyer = lawyer;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.lawyer-view{
    &__image{
        position: relative;
        &__bg{
            position: absolute;
            z-index: -1;
            &--1{
                width: 100vw;
                height: 50%;
                background-color: var(--v-grey-lighten5);
                right: calc(var(--container-gutter) * -1);
                bottom: 0;

            }
            &--2{
                left: 0;
                top: 0;
                width: 100%;
            }
        }
    }
    &__title{
        width: 100%;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .lawyer-view{
        &__image{
            position: relative;
            &__bg{
                &--1{
                    height: 318px;
                    right: 0;
                }
                &--2{
                    top: unset;
                    bottom: 300px;                    
                }
            }
        }
        &__title{
            height: 318px;
        }
    }
}
@media (min-width:1024px){
    .lawyer-view{
        &__title{
            padding-left: 40px;
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .lawyer-view{
        &__title{
            padding-left: 110px;
        }
        &__image{
            position: relative;
            &__bg{
                &--2{
                    bottom: 290px;                    
                }
            }
        }
    }
}
</style>
