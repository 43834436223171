var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-visual",
    class: {
      'sub-visual--tabs': _vm.tabActive
    }
  }, [_vm.bg ? _c('v-responsive', {
    staticClass: "sub-visual__bg",
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "600",
      "max-width": "1920",
      "aspect-ratio": 1920 / 402
    }
  }) : _vm._e(), _c('div', {
    staticClass: "sub-visual__inner w-100"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    staticClass: "mb-10px mb-md-0",
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('tit-wrap-primary', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([{
      key: "TitHead",
      fn: function () {
        return [_vm._t("titHead")];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("tit")], 2)], 1), _vm.tabActive ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('gnb', {
    attrs: {
      "gnbName": "sub-tab",
      "tabActive": _vm.tabActive,
      "sh": _vm.sh
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }