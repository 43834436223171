<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>header-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="12">
                <header-primary></header-primary>
                <br />
            </v-col>
            <v-col cols="12">
                <header-secondary></header-secondary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Fixed Scroll -->
        <h1>Fixed Scroll</h1>
        <p>스크롤시에도 헤더가 상단에 위치합니다.</p>
        <p><u>u-header-fixed-scroll</u></p>
        <!-- // Fixed Scroll -->
    </div>
</template>

<script>
import HeaderPrimary from "@/sets/parents/headers/header-primary.vue";
import HeaderSecondary from "@/sets/parents/headers/header-secondary.vue";

export default {
    props: {},
    components: {
        HeaderPrimary,
        HeaderSecondary,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// 확인을 위한 변경
::v-deep {
    .header {
        position: relative !important;
    }
}
</style>
