<template>
    <router-link v-bind="$attrs" class="lawyer-item">
        <hover-line>
            <v-img :src="image" :aspect-ratio="330 / 494" max-width="330" class="w-100" />
            <template #foot>
                <div class="lawyer-item__contents pa-16px pa-md-32px">
                    <u-txt-default class="tit--sm white--text line-height-14 mb-8px mb-md-16px">
                        <slot name="txt" />
                    </u-txt-default>
                    <u-tit-default class="tit--sm white--text line-height-14"><slot /></u-tit-default>
                </div>
            </template>
        </hover-line>                       
    </router-link>     
</template>

<script>
import HoverLine from "@/components/publish/styles/hover/hover-line.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {
        image: { type: String, default: "" },
    },
    components: {
        HoverLine,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
}
</script>

<style lang="scss" scoped>
.lawyer-item{
    display: block;
    position: relative;
    &__contents{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background-color: rgba(13, 25, 50, .6);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>