<template>
    <lnb class="lnb--topline">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </lnb>
</template>

<script>
import Lnb from "@/components/publish/styles/headers/lnb.vue";

export default {
    props: {},
    components: {
        Lnb,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.lnb--topline ::v-deep {
    top: calc(var(--header-body-height) - 4px);
    border: 1px solid var(--border-color);
    border-top: 4px solid var(--v-grey-darken4);
    > li {
        > a {
            &:hover {
                color: var(--v-grey-darken4);
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}
</style>
