var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', _vm._b({
    staticClass: "lawyer-item"
  }, 'router-link', _vm.$attrs, false), [_c('hover-line', {
    scopedSlots: _vm._u([{
      key: "foot",
      fn: function () {
        return [_c('div', {
          staticClass: "lawyer-item__contents pa-16px pa-md-32px"
        }, [_c('u-txt-default', {
          staticClass: "tit--sm white--text line-height-14 mb-8px mb-md-16px"
        }, [_vm._t("txt")], 2), _c('u-tit-default', {
          staticClass: "tit--sm white--text line-height-14"
        }, [_vm._t("default")], 2)], 1)];
      },
      proxy: true
    }], null, true)
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.image,
      "aspect-ratio": 330 / 494,
      "max-width": "330"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }