<template>
    <div>
        <v-tabs v-model="tab" grow class="mb-30px">
            <v-tab>Header Default</v-tab>
            <v-tab>판심 법무법인</v-tab>
            <v-tab>Gnb</v-tab>
            <v-tab>Lnb</v-tab>
            <v-tab>User Items</v-tab>
            <v-tab>Language Items</v-tab>
            <v-tab>Header Search</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item><HeadersDefault /></v-window-item>
            <v-window-item><HeadersLogo /></v-window-item>
            <v-window-item><HeadersGnb /></v-window-item>
            <v-window-item><HeadersLnb /></v-window-item>
            <v-window-item><HeadersUserItems /></v-window-item>
            <v-window-item><HeadersLanguageItems /></v-window-item>
            <v-window-item><HeadersHeaderSearch /></v-window-item>
        </v-window>
    </div>
</template>

<script>

import HeadersDefault from "./headers-default.vue";
import HeadersLogo from "./headers-logo.vue";
import HeadersGnb from "./headers-gnb.vue";
import HeadersLnb from "./headers-lnb.vue";
import HeadersUserItems from "./headers-user-items.vue";
import HeadersLanguageItems from "./headers-language-items.vue";
import HeadersHeaderSearch from "./headers-header-search.vue";

export default {
    props: {},
    components: {
        HeadersDefault,
        HeadersLogo,
        HeadersGnb,
        HeadersLnb,
        HeadersUserItems,
        HeadersLanguageItems,
        HeadersHeaderSearch,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
