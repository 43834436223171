<template>
    <u-btn-arrow-text-fit v-bind="$attrs" @click="$emit('click')">
        <icon-arrow-primary v-bind="{ direction }" />
    </u-btn-arrow-text-fit>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import UBtnArrowTextFit from "@/components/publish/styles/buttons/u-btn-arrow-text-fit.vue";

export default {
    props: {
        direction: { type: String, default: "left" },
    },
    components: {
        IconArrowPrimary,
        UBtnArrowTextFit,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
