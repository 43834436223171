<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>rating-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <rating-primary v-model="rating">(3.5)</rating-primary>
            </v-col>
            <v-col cols="auto">
                <rating-secondary v-model="rating"></rating-secondary>
            </v-col>
            <v-col cols="auto">
                <rating-tertiary v-model="rating">
                    <span class="font-size-16 font-weight-medium rating-color">3.5</span>
                </rating-tertiary>
            </v-col>
            <v-col cols="auto">
                <rating-quarternary v-model="rating"></rating-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Size -->
        <h1>Size</h1>
        <h3>{size} : x-small, small, (기본), large, x-large</h3>
        <p><u>class="v-size--{size}"</u></p>
        <v-row align="center">
            <v-col cols="auto">
                <v-rating v-model="rating" class="v-size--x-small"></v-rating>
            </v-col>
            <v-col cols="auto">
                <v-rating v-model="rating" class="v-size--small"></v-rating>
            </v-col>
            <v-col cols="auto">
                <v-rating v-model="rating"></v-rating>
            </v-col>
            <v-col cols="auto">
                <v-rating v-model="rating" class="v-size--large"></v-rating>
            </v-col>
            <v-col cols="auto">
                <v-rating v-model="rating" class="v-size--x-large"></v-rating>
            </v-col>
        </v-row>
        <!-- // Size -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>class="rating-color"</u></p>
        <p>기본으로 primary컬러가 들어갑니다. rating용 기본컬러는 rating-color를 넣어 적용해주세요.</p>
        <v-row align="center">
            <v-col cols="auto">
                <v-rating v-model="rating" class="rating-color"></v-rating>
            </v-col>
            <v-col cols="auto">
                <v-rating v-model="rating" color="secondary" background-color="secondary"></v-rating>
            </v-col>
        </v-row>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Readonly -->
        <h2>Readonly</h2>
        <p><u>readonly</u></p>
        <v-row align="center">
            <v-col cols="auto">
                <v-rating v-model="rating" readonly></v-rating>
            </v-col>
        </v-row>
        <!-- E: Readonly -->
        <br />

        <!-- S: Half Increments -->
        <h2>Half Increments</h2>
        <p><u>half-increments</u></p>
        <v-row align="center">
            <v-col cols="auto">
                <v-rating v-model="rating" half-increments></v-rating>
            </v-col>
        </v-row>
        <!-- E: Half Increments -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Icons -->
        <h1>Icons</h1>
        <v-row align="center">
            <v-col cols="auto">
                <v-rating v-model="rating" half-increments class="rating-color">
                    <template v-slot:item="props">
                        <span v-if="props.isFilled" @click="props.click">
                            <u-icon variant="round" type="button">star</u-icon>
                        </span>
                        <span v-else-if="props.isHalfFilled" @click="props.click">
                            <u-icon variant="round" type="button">star_half</u-icon>
                        </span>
                        <span v-else @click="props.click">
                            <u-icon variant="round" type="button">star_border</u-icon>
                        </span>
                    </template>
                </v-rating>
            </v-col>
            <v-col cols="auto">
                <v-rating v-model="rating" half-increments class="rating-color">
                    <template v-slot:item="props">
                        <span v-if="props.isFilled" @click="props.click">
                            <u-icon variant="round" type="button">star</u-icon>
                        </span>
                        <span v-else-if="props.isHalfFilled" class="v-rating__half-filled" @click="props.click">
                            <u-icon variant="round" type="button" class="v-rating__filled">star</u-icon>
                            <u-icon variant="round" type="button"><span class="secondary--text">star</span></u-icon>
                        </span>
                        <span v-else @click="props.click">
                            <u-icon variant="round" type="button"><span class="secondary--text">star</span></u-icon>
                        </span>
                    </template>
                </v-rating>
            </v-col>
        </v-row>
        <!-- Icons -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import RatingPrimary from "@/components/publish/parents/ratings/rating-primary.vue";
import RatingSecondary from "@/components/publish/parents/ratings/rating-secondary.vue";
import RatingTertiary from "@/components/publish/parents/ratings/rating-tertiary.vue";
import RatingQuarternary from "@/components/publish/parents/ratings/rating-quarternary.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        GuideApp,

        RatingPrimary,
        RatingSecondary,
        RatingTertiary,
        RatingQuarternary,

        UIcon,
    },
    data: () => {
        return {
            rating: 3.5,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
