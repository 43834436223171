<template>
    <u-pagination-filled-tile v-bind="$attrs"></u-pagination-filled-tile>
</template>

<script>
import UPaginationFilledTile from "@/components/publish/styles/paginations/u-pagination-filled-tile.vue";

export default {
    props: {},
    components: {
        UPaginationFilledTile,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
