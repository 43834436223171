<template>
    <swiper-pagination v-bind="$attrs" class="swiper-pagination--outlined"><slot /></swiper-pagination>
</template>

<script>
import SwiperPagination from "@/components/publish/styles/swiper/swiper-pagination.vue";

export default {
    props: {},
    components: { SwiperPagination },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-pagination--outlined {
    ::v-deep {
        .swiper-pagination {
            &-bullet {
                opacity: 1;
                background-color: transparent;
                &-active {
                    background-color: var(--v-primary-base);
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
