<template>
    <v-checkbox v-bind="{ ...attrs_controls_common, ...$attrs }" color="secondary">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-checkbox>
</template>

<script>
import { attrs_controls_common } from "@/assets/variables";

export default {
    props: {},
    components: {},
    data: () => {
        return {
            attrs_controls_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
