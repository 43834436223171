<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>pagination-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <pagination-primary v-model="page" :length="4"></pagination-primary>
            </v-col>
            <v-col cols="6" md="3">
                <pagination-secondary v-model="page" :length="4"></pagination-secondary>
            </v-col>
            <v-col cols="6" md="3">
                <pagination-tertiary v-model="page" :length="4"></pagination-tertiary>
            </v-col>
            <v-col cols="6" md="3">
                <pagination-quarternary v-model="page" :length="4"></pagination-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Text -->
        <h2>Text</h2>
        <p><u>u-pagination-text-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <u-pagination-text-tile v-model="page" :length="4"></u-pagination-text-tile>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-text-rounded v-model="page" :length="4"></u-pagination-text-rounded>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-text-circle v-model="page" :length="4"></u-pagination-text-circle>
            </v-col>
        </v-row>
        <!-- E: Text -->
        <br />

        <!-- S: Elevated -->
        <h2>Elevated</h2>
        <p><u>u-pagination-elevated-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <u-pagination-elevated-tile v-model="page" :length="4"></u-pagination-elevated-tile>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-elevated-rounded v-model="page" :length="4"></u-pagination-elevated-rounded>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-elevated-circle v-model="page" :length="4"></u-pagination-elevated-circle>
            </v-col>
        </v-row>
        <!-- E: Elevated -->
        <br />

        <!-- S: Outlined -->
        <h2>Outlined</h2>
        <p><u>u-pagination-outlined-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <u-pagination-outlined-tile v-model="page" :length="4"></u-pagination-outlined-tile>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-outlined-rounded v-model="page" :length="4"></u-pagination-outlined-rounded>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-outlined-circle v-model="page" :length="4"></u-pagination-outlined-circle>
            </v-col>
        </v-row>
        <!-- E: Outlined -->
        <br />

        <!-- S: Filled -->
        <h2>Filled</h2>
        <p><u>u-pagination-filled-{}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <u-pagination-filled-tile v-model="page" :length="4"></u-pagination-filled-tile>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-filled-rounded v-model="page" :length="4"></u-pagination-filled-rounded>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-filled-circle v-model="page" :length="4"></u-pagination-filled-circle>
            </v-col>
        </v-row>
        <!-- E: Filled -->
        <br />

        <!-- S: Text Custom -->
        <h2>Text Custom</h2>
        <p><u>u-pagination-text-color</u></p>
        <p><u>u-pagination-text-underline</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <u-pagination-text-color v-model="page" :length="4"></u-pagination-text-color>
            </v-col>
            <v-col cols="6" md="4">
                <u-pagination-text-underline v-model="page" :length="4"></u-pagination-text-underline>
            </v-col>
        </v-row>
        <!-- E: Text Custom -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Size -->
        <h1>Size</h1>
        <h3>{size} : x-small, small, (기본), large, x-large</h3>
        <p><u>class="v-size--{size}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-size--x-small"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-size--small"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-size--large"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-size--x-large"></v-pagination>
            </v-col>
        </v-row>
        <!-- // Size -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Disabled -->
        <h2>Disabled</h2>
        <p><u>disabled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" disabled></v-pagination>
            </v-col>
        </v-row>
        <!-- E: Disabled -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (기본), circle, 0</h3>
        <p><u>class="rounded-{rounded}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="rounded-circle"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="rounded-0"></v-pagination>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Variant -->
        <h1>Variant</h1>
        <h3>{variant} : (기본-text), elevated, outlined, filled, text-color, text-underline</h3>
        <p><u>class="v-pagination--{variant}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-pagination--elevated"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-pagination--outlined"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-pagination--filled"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-pagination--text-color"></v-pagination>
            </v-col>
            <v-col cols="6" md="4">
                <v-pagination v-model="page" :length="4" class="v-pagination--text-underline"></v-pagination>
            </v-col>
        </v-row>
        <!-- Variant -->
    </div>
</template>

<script>
import PaginationPrimary from "@/components/publish/parents/paginations/pagination-primary.vue";
import PaginationSecondary from "@/components/publish/parents/paginations/pagination-secondary.vue";
import PaginationTertiary from "@/components/publish/parents/paginations/pagination-tertiary.vue";
import PaginationQuarternary from "@/components/publish/parents/paginations/pagination-quarternary.vue";

import UPaginationTextTile from "@/components/publish/styles/paginations/u-pagination-text-tile.vue";
import UPaginationTextRounded from "@/components/publish/styles/paginations/u-pagination-text-rounded.vue";
import UPaginationTextCircle from "@/components/publish/styles/paginations/u-pagination-text-circle.vue";
import UPaginationElevatedTile from "@/components/publish/styles/paginations/u-pagination-elevated-tile.vue";
import UPaginationElevatedRounded from "@/components/publish/styles/paginations/u-pagination-elevated-rounded.vue";
import UPaginationElevatedCircle from "@/components/publish/styles/paginations/u-pagination-elevated-circle.vue";
import UPaginationOutlinedTile from "@/components/publish/styles/paginations/u-pagination-outlined-tile.vue";
import UPaginationOutlinedRounded from "@/components/publish/styles/paginations/u-pagination-outlined-rounded.vue";
import UPaginationOutlinedCircle from "@/components/publish/styles/paginations/u-pagination-outlined-circle.vue";
import UPaginationFilledTile from "@/components/publish/styles/paginations/u-pagination-filled-tile.vue";
import UPaginationFilledRounded from "@/components/publish/styles/paginations/u-pagination-filled-rounded.vue";
import UPaginationFilledCircle from "@/components/publish/styles/paginations/u-pagination-filled-circle.vue";
import UPaginationTextColor from "@/components/publish/styles/paginations/u-pagination-text-color.vue";
import UPaginationTextUnderline from "@/components/publish/styles/paginations/u-pagination-text-underline.vue";

export default {
    props: {},
    components: {
        PaginationPrimary,
        PaginationSecondary,
        PaginationTertiary,
        PaginationQuarternary,

        UPaginationTextTile,
        UPaginationTextRounded,
        UPaginationTextCircle,
        UPaginationElevatedTile,
        UPaginationElevatedRounded,
        UPaginationElevatedCircle,
        UPaginationOutlinedTile,
        UPaginationOutlinedRounded,
        UPaginationOutlinedCircle,
        UPaginationFilledTile,
        UPaginationFilledRounded,
        UPaginationFilledCircle,
        UPaginationTextColor,
        UPaginationTextUnderline,
    },
    data: () => {
        return {
            page: 1,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
