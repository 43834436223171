<template>
    <u-text-field-filled-tile v-bind="$attrs" @searchValue="searchValue" @search="$emit('search')">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-text-field-filled-tile>
</template>

<script>
import UTextFieldFilledTile from "@/components/publish/styles/forms/u-text-field-filled-tile.vue";

export default {
    props: {},
    components: {
        UTextFieldFilledTile,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {
        searchValue(searchValue) {
            this.$emit("searchValue", searchValue)
        }
    },
};
</script>

<style lang="scss" scoped></style>
