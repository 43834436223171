<template>
    <div class="v-rating-wrap">
        <v-rating v-bind="$attrs" half-increments color="primary" background-color="primary" @click="$emit('click')"></v-rating>
        <span v-if="this.$slots['default']" class="v-rating-wrap__txt"><slot /></span>
    </div>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        UIcon,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
