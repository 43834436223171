<template>
    <td v-bind="$attrs" class="primary--text">
        <slot />
    </td>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
