var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('board-filter', {
    staticClass: "mb-30px mb-md-68px",
    attrs: {
      "data-aos": "fade-up"
    },
    on: {
      "searchKey": function (searchKey) {
        _vm.filter.searchKey = searchKey;
      },
      "searchValue": function (searchValue) {
        _vm.filter.searchValue = searchValue;
      },
      "search": _vm.search
    }
  }), _c('v-row', {
    attrs: {
      "data-aos": "fade-up"
    }
  }, _vm._l(_vm.boards, function (board) {
    return _c('v-col', {
      key: board._id,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      staticClass: "recent-item v-card--none-active-bg plus-btn-card",
      attrs: {
        "to": `/recent/view/${board._id}`,
        "tile": "",
        "flat": "",
        "color": "transparent"
      }
    }, [_c('div', {
      staticClass: "recent-item__thumb-wrap"
    }, [_c('v-img', {
      staticClass: "recent-item__thumb w-100",
      attrs: {
        "src": board.thumb,
        "max-width": "464",
        "aspect-ratio": 1 / 1
      }
    })], 1), _c('div', {
      staticClass: "py-18px py-md-36px"
    }, [_c('u-txt-default', {
      staticClass: "txt--sm txt--light line-height-14"
    }, [_c('v-row', {
      staticClass: "row--xxs",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', [_c('p', [_vm._v("언론")])]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('p', [_vm._v(_vm._s(_vm.$dayjs(board.createdAt).format("YYYY.MM.DD")))])])], 1)], 1), _c('u-tit-medium', {
      staticClass: "tit--sm line-height-14 text-truncate-2 mt-8px mt-md-16px"
    }, [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")]), _c('icon-plus', {
      staticClass: "mt-20px mt-md-60px"
    })], 1)])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }