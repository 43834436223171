<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="Recent Insights">
                <template #titHead>R</template>
                <template #tit>ecent Insights</template>
            </sub-visual>
        </template>
        <page-section class="page-section--first page-section--last">
            <media-view data-aos="fade-up" :board="board" />
        </page-section>
    </app-primary>
</template>

<script>
import api from "@/api"
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import MediaView from "@/components/client/board/skin/media/view.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        MediaView,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
            board: null
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            let { board } = await api.v1.boards.get({ _id: this.$route.params._board })
            this.board = board;
        }
    },
};
</script>

<style lang="scss" scoped></style>
