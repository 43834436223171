<template>
    <u-scrolldown-filled v-bind="$attrs" verticalWide></u-scrolldown-filled>
</template>

<script>
import UScrolldownFilled from "@/components/publish/styles/scrolldowns/u-scrolldown-filled.vue";

export default {
    props: {},
    components: {
        UScrolldownFilled,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
