<template>
    <v-radio v-bind="$attrs">
        <template v-slot:label>
            <strong class="red--text">{{ label }}</strong>
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-radio>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "" },
    },
    components: {},
    data: () => {
        return {
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
