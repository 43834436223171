var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-r border-l"
  }, [_vm.topLine ? _c('v-divider', {
    style: _vm.dark ? 'border-color: rgba(255, 255, 255, .2)' : ''
  }) : _vm._e(), _c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-48px"
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    staticClass: "mb-n8px mb-md-0",
    attrs: {
      "data-aos": "fade-up",
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-medium', {
    staticClass: "tit--sm line-height-14",
    class: _vm.dark ? 'white--text' : ''
  }, [_vm._v(_vm._s(_vm.title))])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-txt-default', {
    staticClass: "txt--lg",
    class: _vm.dark ? 'white--text' : ''
  }, [_vm._t("default")], 2)], 1)], 1)], 1), _c('v-divider', {
    style: _vm.dark ? 'border-color: rgba(255, 255, 255, .2)' : ''
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }