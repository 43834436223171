var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxs"
  }, [!_vm.dotNone ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v(" · ")]) : _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("   ")]), _c('v-col', [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }