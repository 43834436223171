var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _vm._m(0), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-pagination-primary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-pagination-secondary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-pagination-tertiary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-pagination-quarternary')], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Styles")]), _c('p', [_vm._v("swiper-pagination-bullet는 템플릿 작업을 위해 넣은 놓은 것입니다.")]), _c('p', [_vm._v("사용시 swiper-pagination-bullet는 지우고 u-swiper-pagination-{}만 사용해주세요.")]), _c('br'), _c('h2', [_vm._v("Filled")]), _vm._m(1), _c('br'), _c('u-swiper-pagination-filled'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-filled', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('br'), _c('h2', [_vm._v("Ellipse")]), _vm._m(2), _c('br'), _c('u-swiper-pagination-ellipse'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-ellipse', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-ellipse', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('br'), _c('h2', [_vm._v("Outlined")]), _vm._m(3), _c('br'), _c('u-swiper-pagination-outlined'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-outlined', {
    staticClass: "swiper-pagination--sm"
  }, [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-swiper-pagination-outlined', [_c('span', {
    staticClass: "swiper-pagination-bullet swiper-pagination-bullet-active"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  }), _c('span', {
    staticClass: "swiper-pagination-bullet"
  })])], 1)], 1), _c('br')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("swiper-pagination-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-filled")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-ellipse")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("u-swiper-pagination-outlined")])]);

}]

export { render, staticRenderFns }