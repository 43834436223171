import { render, staticRenderFns } from "./u-tooltip-elevation-pill.vue?vue&type=template&id=ad20b6e4&scoped=true"
import script from "./u-tooltip-elevation-pill.vue?vue&type=script&lang=js"
export * from "./u-tooltip-elevation-pill.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad20b6e4",
  null
  
)

export default component.exports