<template>
    <section class="page-section">
        <slot v-if="this.$slots['pageSectionHead']" name="pageSectionHead" />
        <v-container v-if="this.$slots['default']">
            <slot />
        </v-container>
        <slot v-if="this.$slots['pageSectionFoot']" name="pageSectionFoot" />
    </section>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function() {},
    },
};
</script>

<style lang="scss" scoped>
.page-section {
    position: relative;
    padding: var(--page-section-padding-y) 0;
    overflow: hidden;
    .container {
        position: relative;
    }
    &--xs {
        padding: var(--page-section-padding-y-xs) 0;
    }
    &--sm {
        padding: var(--page-section-padding-y-sm) 0;
    }
    &--first {
        padding-top: var(--contents-padding-top);
        margin-top: calc(var(--contents-padding-top) * -1);
    }
    &--last {
        padding-bottom: var(--contents-padding-bottom);
        margin-bottom: calc(var(--contents-padding-bottom) * -1);
    }
    &__head{
        padding-bottom: var(--tit-wrap-padding-bottom);
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
