var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--contact page-section--first page-section--last",
    scopedSlots: _vm._u([{
      key: "pageSectionHead",
      fn: function () {
        return [_c('iframe', {
          staticClass: "d-block",
          staticStyle: {
            "border": "0"
          },
          attrs: {
            "data-aos": "fade-up",
            "src": _vm.iframe,
            "width": "100%",
            "height": "500",
            "allowfullscreen": "",
            "loading": "lazy",
            "referrerpolicy": "no-referrer-when-downgrade"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mt-40px mt-md-80px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--lg font-weight-bold line-height-14",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 이제 "), _c('br'), _c('span', {
    staticClass: "primary--text text--lighten-3"
  }, [_vm._v("판사 출신 문유진 변호사")]), _vm._v("를 "), _c('br'), _vm._v(" 믿고 가야 할 때입니다. ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "ml-md-auto",
    attrs: {
      "max-width": "612",
      "color": "transparent"
    }
  }, [_c('v-img', {
    staticClass: "w-100",
    attrs: {
      "data-aos": "fade-up",
      "src": _vm.image,
      "aspect-ratio": 1 / 1
    }
  }), _c('div', {
    staticClass: "pt-24px pt-md-48px"
  }, [_c('u-tit-medium', {
    staticClass: "tit--sm line-height-17 mb-8px mb-md-16px font-primary",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._v(" " + _vm._s(_vm.address) + " ")]), _c('u-txt-default', {
    staticClass: "txt--lg"
  }, [_c('v-row', {
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "400",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "font-weight-medium txt--light pr-10px pr-md-32px"
  }, [_vm._v("업무시간")])]), _c('v-col', [_c('p', {
    staticClass: "txt--dark"
  }, [_vm._v(" 24시간 연중무휴 (긴급대응 가능 "), _c('a', {
    attrs: {
      "href": "tel:010-8702-0200"
    }
  }, [_vm._v("010-8702-0200")]), _vm._v(") ")])])], 1), _vm.tel ? _c('v-row', {
    staticClass: "mt-4px mt-md-8px",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "600",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "font-weight-medium txt--light pr-10px pr-md-32px"
  }, [_vm._v("상담예약")])]), _c('v-col', [_c('a', {
    staticClass: "txt--dark",
    attrs: {
      "href": 'tel:' + _vm.tel
    }
  }, [_vm._v(_vm._s(_vm.tel))])])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }