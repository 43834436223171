<template>
    <u-text-field-outlined-pill v-bind="$attrs" class="v-text-field--label-inner">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-text-field-outlined-pill>
</template>

<script>
import UTextFieldOutlinedPill from "@/components/publish/styles/forms/u-text-field-outlined-pill.vue";

export default {
    props: {},
    components: {
        UTextFieldOutlinedPill,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
