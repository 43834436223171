<template>
    <u-scrolldown-mouse v-bind="$attrs"></u-scrolldown-mouse>
</template>

<script>
import UScrolldownMouse from "@/components/publish/styles/scrolldowns/u-scrolldown-mouse.vue";

export default {
    props: {},
    components: {
        UScrolldownMouse,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
