<template>
    <div class="sub-visual" :class="{ 'sub-visual--tabs': tabActive }">
        <v-responsive v-if="bg" data-aos="zoom-out" data-aos-delay="600" max-width="1920" :aspect-ratio="1920 / 402" class="sub-visual__bg" />
        <div class="sub-visual__inner w-100">
            <v-container>
                <v-row align="end">
                    <v-col cols="12" md="" class="mb-10px mb-md-0">
                        <tit-wrap-primary class="pb-0">
                            <template #TitHead><slot name="titHead" /></template>
                            <slot name="tit" />
                        </tit-wrap-primary>
                    </v-col>
                    <v-col v-if="tabActive" cols="12" md="auto"><gnb gnbName="sub-tab" :tabActive="tabActive" :sh="sh" /></v-col>
                </v-row>  
            </v-container>   
        </div>   
    </div>
</template>

<script>
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";
import Gnb from "@/components/publish/styles/headers/gnb.vue";

export default {
    props: {
        sh: { type: String, default: "" },
        tabActive: { type: String, default: "" },
        bg: { type: Boolean, default: false },
    },
    components: {
        TitWrapPrimary,
        Gnb,
    },
    data: () => {
        return {};
    },
};
</script>

<style lang="scss" scoped>
/* sub-visual */
.sub-visual {
    position:relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 180px;
    &__bg{
        position: absolute;
        width: 100%;
        z-index: -1;
        right: 0;
        top: var(--header-body-height);
        background: url(/images/sub/sv-bg.svg) no-repeat center / cover;
    }
    &--tabs{
        height: 230px;
    }
}
::v-deep{
    .sub-tab{
        position: relative;
        width: calc(100% + (var(--container-gutter) * 2));
        left: calc(var(--container-gutter) * -1);
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: var(--v-grey-lighten4);
            bottom: 0;
            left: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 240px;
        padding-top:0;
    }
    ::v-deep{
        .sub-tab{
            position: relative;
            width: 100%;
            left: 0;
            &::before{
                display: none;
            }
        }
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 340px;
    }
}
@media (min-width: 1200px) {
    .sub-visual {
        &__bg{
            min-width: 1920px;
        }
    }
}
</style>
