var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', [_vm._v("Parents")]), _c('h2', [_vm._v("Radio")]), _vm._m(0), _c('br'), _c('v-radio-group', _vm._b({
    attrs: {
      "row": ""
    }
  }, 'v-radio-group', Object.assign({}, _vm.attrs_controls_common), false), [_c('radio-primary', {
    attrs: {
      "label": "Radio",
      "value": "1"
    }
  }), _c('radio-secondary', {
    attrs: {
      "label": "Radio",
      "value": "1"
    }
  }), _c('radio-tertiary', {
    attrs: {
      "label": "Radio",
      "value": "1"
    }
  }), _c('radio-quarternary', {
    attrs: {
      "label": "Radio",
      "value": "1"
    }
  })], 1), _c('br'), _c('h2', [_vm._v("Checkbox")]), _vm._m(1), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-primary', {
    attrs: {
      "label": "Checkbox",
      "value": "1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-secondary', {
    attrs: {
      "label": "Checkbox",
      "value": "1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-tertiary', {
    attrs: {
      "label": "Checkbox",
      "value": "1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('checkbox-quarternary', {
    attrs: {
      "label": "Checkbox",
      "value": "1"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Density")]), _c('h3', [_vm._v("{density} : compact, comfortable, (기본-default)")]), _vm._m(2), _c('br'), _c('h2', [_vm._v("Radio")]), _c('v-radio-group', _vm._b({
    attrs: {
      "row": ""
    }
  }, 'v-radio-group', Object.assign({}, _vm.attrs_controls_common), false), [_c('v-radio', {
    staticClass: "v-input--density-compact",
    attrs: {
      "label": "Radio",
      "value": "1"
    }
  }), _c('v-radio', {
    staticClass: "v-input--density-comfortable",
    attrs: {
      "label": "Radio",
      "value": "2"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Radio",
      "value": "3"
    }
  })], 1), _c('br'), _c('h2', [_vm._v("Checkbox")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    staticClass: "v-input--density-compact",
    attrs: {
      "label": "Checkbox",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    staticClass: "v-input--density-comfortable",
    attrs: {
      "label": "Checkbox",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "label": "Checkbox",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("Color")]), _vm._m(3), _c('br'), _c('h2', [_vm._v("Radio")]), _c('v-radio-group', _vm._b({
    attrs: {
      "row": ""
    }
  }, 'v-radio-group', Object.assign({}, _vm.attrs_controls_common), false), [_c('v-radio', {
    attrs: {
      "label": "Default(Primary)",
      "value": "1"
    }
  }), _c('v-radio', {
    attrs: {
      "color": "secondary",
      "label": "Secondary",
      "value": "1"
    }
  }), _c('v-radio', {
    attrs: {
      "color": "accent",
      "label": "Accent",
      "value": "1"
    }
  }), _c('v-radio', {
    attrs: {
      "color": "grey",
      "label": "Grey",
      "value": "1"
    }
  })], 1), _c('br'), _c('h2', [_vm._v("Checkbox")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "label": "Default(Primary)",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "color": "secondary",
      "label": "Secondary",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "color": "accent",
      "label": "Accent",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "color": "grey",
      "label": "Grey",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1)], 1), _c('v-divider', {
    staticClass: "my-30px"
  }), _c('h1', [_vm._v("State")]), _c('h2', [_vm._v("Radio")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', [_vm._v("Disabled")]), _c('p', [_c('u', [_vm._v("disabled")])]), _c('br'), _c('v-radio-group', _vm._b({
    attrs: {
      "row": ""
    }
  }, 'v-radio-group', Object.assign({}, _vm.attrs_controls_common), false), [_c('v-radio', {
    attrs: {
      "disabled": "",
      "label": "Disabled",
      "value": "1"
    }
  })], 1)], 1)], 1), _c('br'), _c('h2', [_vm._v("Checkbox")]), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', [_vm._v("Disabled")]), _c('p', [_c('u', [_vm._v("disabled")])]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "disabled": "",
      "label": "Disabled",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', [_vm._v("Indeterminate")]), _c('p', [_c('u', [_vm._v("indeterminate")])]), _c('br'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', _vm._b({
    attrs: {
      "indeterminate": "",
      "label": "Indeterminate",
      "value": "1"
    }
  }, 'v-checkbox', Object.assign({}, _vm.attrs_controls_common), false))], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("radio-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("checkbox-{parents}")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("class=\"v-input--density-{density}\"")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('u', [_vm._v("color=\"{color}\"")])]);

}]

export { render, staticRenderFns }