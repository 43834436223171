<template>
    <div>

        <board-filter :filter="filter" data-aos="fade-up" class="mb-30px mb-md-68px" 
        @searchKey="(searchKey) => { filter.searchKey = searchKey }" 
        @searchValue="(searchValue) => {filter.searchValue = searchValue}"
        @search="search"
        />

        <v-row data-aos="fade-up">

            <!-- S: story -->
            <!-- <v-col v-for="board in boards" :key="board._id" cols="6" md="4">
                <v-card :to="`/story/view/${board._id}`" tile flat outlined class="story-item v-card--none-active-bg plus-btn-card">

                    <v-responsive :aspect-ratio="1 / 1" class="w-100 transparent">
                        <div class="story-item__inner pa-20px pa-md-30px pa-lg-48px">
                            <div class="story-item__tit mb-30px">
                                <u-tit-medium class="tit--sm line-height-14 text-truncate-2">
                                    {{ board.subject }}
                                </u-tit-medium>
                                <u-txt-default class="txt--sm text-truncate-2 mt-8px mt-md-16px">
                                    {{ board.case }}
                                </u-txt-default>
                            </div>
                            <v-row no-gutters align="center" class="mh-30px mh-md-50px">
                                <v-col>
                                    <u-txt-default class="txt--sm txt--light line-height-14">
                                        {{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}
                                    </u-txt-default>
                                </v-col>
                                <v-col cols="auto">
                                    <icon-plus style="color: #D9D9D9"/>
                                </v-col>
                            </v-row>
                        </div>
                    </v-responsive>
                </v-card>
            </v-col> -->
            <!-- E: story -->
            <!-- S: story 썸네일-->
            <v-col v-for="board in boards" :key="board._id" cols="6" md="4">
                <v-card :to="`/recent/view/${board._id}`" tile flat color="transparent" class="recent-item v-card--none-active-bg plus-btn-card">
                    <div class="recent-item__thumb-wrap">
                        <v-img :src="board.thumb" max-width="464" :aspect-ratio="1 / 1" class="recent-item__thumb w-100" />
                    </div>
                    <div class="py-18px py-md-36px">
                        <u-txt-default class="txt--sm txt--light line-height-14">
                            <v-row align="center" class="row--xxs">
                                <v-col>
                                    <p>{{ code === 'story' ? '성공사례' : '대표변호사 칼럼' }}</p>
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <p>{{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}</p>
                                </v-col>
                            </v-row>
                        </u-txt-default>
                        <u-tit-medium class="tit--sm line-height-14 text-truncate-2 mt-8px mt-md-16px">
                            {{ board?.subject }}
                        </u-tit-medium>
                        <icon-plus class="mt-20px mt-md-60px"/>
                    </div>
                </v-card>
            </v-col>
            <!-- E: story 썸네일-->
        </v-row>

    </div>
</template>

<script>
import api from "@/api";
import BoardFilter from "@/components/client/board/board-filter.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {
        // boards: { type: Array, default: () => [] }
    },
    components: {
        BoardFilter,
        IconPlus,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },

    data() {
        return {
            limit: 0,
            boards: [],
            filter: {
                searchKey: "subject",
                searchValue: null,
            }
        }
    },
    computed:{
        code(){
            return this.$route.query.code || 'story'
        }
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            await this.search()
        },
        async search(routable = false){
            if(routable){
                this.router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            this.filter.code = this.code;
        
            var { summary, boards } = await api.v1.boards.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });
        
            this.boards = boards;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    },
    watch: {
        code (){
            console.log("changed")
            this.search();
        }
    }
}
</script>

<style lang="scss" scoped>
.story-item{
    position: relative;
    &__inner{
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s ease-out;
    }
    &__tit{
        ::v-deep{
            .tit,
            .txt{
                transition: .3s ease-out;
            }            
        }
    }
}
.row{
    >[class*="col"]:nth-child(5n - 4){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg.jpg);
        }
    }
    >[class*="col"]:nth-child(5n - 3){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg2.jpg);
        }
    }
    >[class*="col"]:nth-child(5n - 2){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg3.jpg);
        }
    }
    >[class*="col"]:nth-child(5n - 1){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg4.jpg);
        }
    }
    >[class*="col"]:nth-child(5n){
        .story-item::after{
            background-image: url(/images/sub/story/story-bg5.jpg);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .story-item:hover{
        &::after{
            opacity: 1;
            visibility: visible;
        }        
        .story-item__tit{
            ::v-deep{
                .tit{
                    color: #fff !important;
                }  
                .txt{
                    color: var(--v-grey-lighten3) !important;
                }            
            }
        }
    }
}
@media (min-width:1200px){
}

</style>