import { render, staticRenderFns } from "./headers-user-items.vue?vue&type=template&id=4c80d945&scoped=true"
import script from "./headers-user-items.vue?vue&type=script&lang=js"
export * from "./headers-user-items.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c80d945",
  null
  
)

export default component.exports