import { render, staticRenderFns } from "./u-pagination-outlined-tile.vue?vue&type=template&id=1eb9fa21&scoped=true"
import script from "./u-pagination-outlined-tile.vue?vue&type=script&lang=js"
export * from "./u-pagination-outlined-tile.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eb9fa21",
  null
  
)

export default component.exports