var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "The firm",
            "tabActive": "판심 법무법인 소개"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("T")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("he Firm")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('sub-image-title', {
    staticClass: "page-section--first",
    attrs: {
      "title": "Sustainability",
      "image": "/images/sub/firm/about/about-img.jpg"
    },
    scopedSlots: _vm._u([{
      key: "symbol",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "src": "/images/sub/firm/about/about-img2.svg",
            "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 180 : 100,
            "aspect-ratio": 180 / 202
          }
        })];
      },
      proxy: true
    }, {
      key: "leftContents",
      fn: function () {
        return [_c('u-txt-default', {
          staticClass: "txt--lg break-keep",
          attrs: {
            "data-aos": "fade-up"
          }
        }, [_c('p', [_vm._v(" 판심(判心) 은 의뢰인에 입장에서 생각합니다. "), _c('br'), _vm._v(" 누구나 순간의 실수와 오해로 가해자, 피해자가 될 수있습니다. ")]), _c('p', [_vm._v(" 아무나 해결할 수 없는 형사사건, "), _c('br'), _vm._v(" 첫 대응, 첫 시작을 판심(判心)과 함께 하세요. ")])]), _c('u-tit-default', {
          staticClass: "tit--sm font-weight-bold line-height-14 mt-16px mt-md-32px",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "200"
          }
        }, [_vm._v(" 판사출신 변호사의 경험과 풍부한 노하우로 "), _c('br', {
          staticClass: "d-none d-lg-block"
        }), _c('span', {
          staticClass: "primary--text text--lighten-3"
        }, [_vm._v("여러분의 인생의 조력자")]), _vm._v("가 되겠습니다. ")])];
      },
      proxy: true
    }])
  }), _c('sub-image-title', {
    staticClass: "pt-0 page-section--last",
    attrs: {
      "title": "Project",
      "image": "/images/sub/firm/about/about-img3.jpg"
    },
    scopedSlots: _vm._u([{
      key: "symbol",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "src": "/images/sub/firm/about/about-img4.svg",
            "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 222 : 120,
            "aspect-ratio": 222 / 128
          }
        })];
      },
      proxy: true
    }, {
      key: "footContents",
      fn: function () {
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "pr-lg-78px"
        }, [_c('u-txt-default', {
          staticClass: "txt--lg break-keep",
          attrs: {
            "data-aos": "fade-up"
          }
        }, [_c('p', [_vm._v(" 법률분쟁에 직면하게 되면, 짧게는 수개월, 길게는 수년 동안 고통스러운 시간을 보내게 됩니다. "), _c('br'), _vm._v(" 판심은 매 사건마다 프로젝트를 구성하여 오랜 판사 경험을 통해 축적한 전문성을 바탕으로 신속하고 정확한 해결책을 제시해 줄 것입니다. ")])]), _c('div', {
          staticClass: "pt-24px pt-md-48px"
        }, [_c('v-img', {
          staticClass: "mb-12px mb-md-24px",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "200",
            "src": "/images/sub/firm/about/about-icon.svg",
            "max-width": _vm.$vuetify.breakpoint.mdAndUp ? 40 : 30,
            "aspect-ratio": 40 / 36,
            "contain": ""
          }
        }), _c('u-tit-medium', {
          staticClass: "tit--sm line-height-14 font-primary",
          attrs: {
            "data-aos": "fade-up",
            "data-aos-delay": "400"
          }
        }, [_vm._v(" 더불어 Client의 마음을 보듬어 "), _c('br', {
          staticClass: "d-none d-lg-block"
        }), _vm._v(" 걱정 없던 시절로 복귀하여 평온한 일상을 "), _c('br', {
          staticClass: "d-none d-lg-block"
        }), _vm._v(" 다시 영위하도록 하는 것. "), _c('br'), _c('br'), _c('strong', {
          staticClass: "font-weight-bold"
        }, [_vm._v("이것이 "), _c('span', {
          staticClass: "primary--text text--lighten-3"
        }, [_vm._v("“프로젝트 심”의 바람입니다.")])])])], 1)], 1)])], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }