var render = function render(){
  var _this$lawyer, _this$lawyer2, _this$lawyer3, _this$lawyer4, _this$lawyer5, _this$lawyer6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Lawyer",
            "bg": ""
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("L")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("awyer")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--sm"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "lawyer-view__image"
  }, [_c('div', {
    staticClass: "lawyer-view__image__bg lawyer-view__image__bg--1",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-delay": "400",
      "data-aos-offset": 0
    }
  }), _c('v-img', {
    staticClass: "lawyer-view__image__bg lawyer-view__image__bg--2",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-delay": "200",
      "data-aos-offset": 0,
      "src": "/images/sub/lawyer/lawyer-bg.svg",
      "max-width": "952",
      "aspect-ratio": 952 / 116
    }
  }), _c('v-img', {
    staticClass: "w-100 mx-auto",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-offset": 0,
      "src": (_this$lawyer = this.lawyer) === null || _this$lawyer === void 0 ? void 0 : _this$lawyer.viewImage,
      "max-width": "952",
      "aspects-ratio": 952 / 696
    }
  })], 1)]), _c('v-col', {
    staticClass: "d-flex align-end mt-20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "lawyer-view__title"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-tit-default', {
    staticClass: "tit--lg font-weight-bold line-height-14",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-offset": 0
    }
  }, [_vm._v(_vm._s((_this$lawyer2 = this.lawyer) === null || _this$lawyer2 === void 0 ? void 0 : _this$lawyer2.name))])], 1), _c('v-col', [_c('v-divider', {
    staticClass: "grey lighten-3",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200",
      "data-aos-offset": 0
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('u-txt-default', {
    staticClass: "txt--lg txt--dark line-height-14",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "400",
      "data-aos-offset": 0
    }
  }, [(_this$lawyer3 = this.lawyer) !== null && _this$lawyer3 !== void 0 && _this$lawyer3.job ? _c('span', [_vm._v(_vm._s((_this$lawyer4 = this.lawyer) === null || _this$lawyer4 === void 0 ? void 0 : _this$lawyer4.job))]) : _c('span', [_vm._v("변호사")])])], 1)], 1)], 1)])], 1)], 1), (_this$lawyer5 = this.lawyer) !== null && _this$lawyer5 !== void 0 && _this$lawyer5.career ? _c('page-section', {
    staticClass: "page-section--last pt-0"
  }, [_c('info-row', {
    attrs: {
      "title": "약력",
      "topLine": ""
    }
  }, [_vm._l((_this$lawyer6 = this.lawyer) === null || _this$lawyer6 === void 0 ? void 0 : _this$lawyer6.career, function (career, index) {
    return [_c('info-detail', {
      key: index,
      class: index !== 0 ? 'mt-4px' : '',
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "100",
        "dotNone": !career.txt
      }
    }, [_vm._v(" " + _vm._s(career.txt) + " ")])];
  })], 2)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }