<template>
    <page-section class="page-section--contact page-section--first page-section--last">
        <template #pageSectionHead>
            <iframe data-aos="fade-up" class="d-block" :src="iframe" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </template>
        <div class="mt-40px mt-md-80px">
            <v-row>
                <v-col cols="12" md="6">
                    <u-tit-default data-aos="fade-up" class="tit--lg font-weight-bold line-height-14">
                        이제 <br>
                        <span class="primary--text text--lighten-3">판사 출신 문유진 변호사</span>를 <br>
                        믿고 가야 할 때입니다.
                    </u-tit-default>
                </v-col>
                <v-col cols="12" md="6">
                    <v-sheet max-width="612" color="transparent" class="ml-md-auto">
                        <v-img data-aos="fade-up" :src="image" :aspect-ratio="1 / 1" class="w-100"/>
                        <div class="pt-24px pt-md-48px">
                            <u-tit-medium data-aos="fade-up" data-aos-delay="200" class="tit--sm line-height-17 mb-8px mb-md-16px font-primary">
                                {{address}}
                            </u-tit-medium>
                            <u-txt-default class="txt--lg">
                                <v-row data-aos="fade-up" data-aos-delay="400" no-gutters>
                                    <v-col cols="auto">
                                        <p class="font-weight-medium txt--light pr-10px pr-md-32px">업무시간</p>
                                    </v-col>
                                    <v-col>
                                        <p class="txt--dark">
                                            24시간 연중무휴 (긴급대응 가능 <a href="tel:010-8702-0200">010-8702-0200</a>)
                                        </p>
                                    </v-col>
                                </v-row>
                                <v-row v-if="tel" data-aos="fade-up" data-aos-delay="600" no-gutters class="mt-4px mt-md-8px">
                                    <v-col cols="auto">
                                        <p class="font-weight-medium txt--light pr-10px pr-md-32px">상담예약</p>
                                    </v-col>
                                    <v-col>
                                        <a class="txt--dark" :href="'tel:'+tel">{{tel}}</a>
                                    </v-col>
                                </v-row>
                            </u-txt-default>
                        </div>
                    </v-sheet>
                </v-col>
            </v-row>
        </div>
    </page-section>
</template>

<script>
import PageSection from "@/sets/styles/pages/page-section.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {
        iframe : String,
        address : String,
        image : String,
        tel : String,
    },
    components: {
        PageSection,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
}
</script>

<style lang="scss" scoped>
.page-section.page-section--contact{
    background-image: url(/images/sub/contact/contact-bg.svg);
    background-position: left bottom;
    background-size: 100% auto;
}
</style>