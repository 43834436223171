<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="Contact Us" tabActive="수원분사무소">
                <template #titHead>C</template>
                <template #tit>ontact Us</template>
            </sub-visual>
        </template>
        <sub-contact
        iframe="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3174.1517930127693!2d127.06578927635245!3d37.29153613984852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5b11bd2701bd%3A0xfe8f00df9aabd7ed!2z6rK96riw64-EIOyImOybkOyLnCDsmIHthrXqtawg6rSR6rWQ7KSR7JWZ66GcMjQ467KI6ri4IDk1LTE!5e0!3m2!1sko!2skr!4v1694507999927!5m2!1sko!2skr"
        address="경기도 수원시 영통구 광교중앙로 248번길 95-1, 7층 (광교법조타운)"
        image="/images/sub/contact/suwon/suwon-img.jpg"
        tel="031-213-0522" />
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import SubContact from "@/sets/styles/sub/sub-contact.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        SubContact,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
