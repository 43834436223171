var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._b({
    staticClass: "icon-svg icon-search",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, 'svg', _vm.$attrs, false), [_c('g', [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.7437 0C4.81016 0 0 4.81016 0 10.7437C0 16.6773 4.81016 21.4875 10.7437 21.4875C13.3969 21.4875 15.8255 20.5257 17.6999 18.9318L22.7685 24.0004L24.0004 22.7685L18.9318 17.6999C20.5257 15.8255 21.4875 13.3969 21.4875 10.7437C21.4875 4.81016 16.6773 0 10.7437 0ZM1.74223 10.7437C1.74223 5.77237 5.77237 1.74223 10.7437 1.74223C15.7151 1.74223 19.7453 5.77237 19.7453 10.7437C19.7453 15.7151 15.7151 19.7453 10.7437 19.7453C5.77237 19.7453 1.74223 15.7151 1.74223 10.7437Z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }