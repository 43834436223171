<template>
    <page-section>
        <template #pageSectionHead>
            <v-img data-aos="fade-up" :src="image" max-width="1920" :aspect-ratio="1920 / 500" class="w-100 mx-auto" />
        </template>
        <div class="sub-image-title__contents pt-40px pt-md-80px">
            <div class="sub-image-title__title">
                <u-tit-default data-aos="fade-up" data-aos-delay="200" class="tit--xxl">
                    {{title}}
                </u-tit-default>
            </div>
            <v-row>
                <v-col cols="12" md="">
                    <div data-aos="fade-up" data-aos-delay="200" class="pt-16px">
                        <slot name="symbol" />
                    </div>
                </v-col>
                <v-col v-if="this.$slots['leftContents']" cols="12" md="" class="mt-20px mt-md-0">
                    <div class="pl-md-20px pl-lg-96px">
                        <slot name="leftContents" />
                    </div>
                </v-col>
            </v-row>
            <div v-if="this.$slots['footContents']" class="pt-40px pt-md-80px">
                <slot name="footContents" />
            </div>
        </div>
    </page-section>
</template>

<script>
import PageSection from "@/sets/styles/pages/page-section.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";
export default {
    props: {
        title: { type: String, default: "" },
        image: { type: String, default: "" },
    },
    components: {
        PageSection,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
}
</script>

<style lang="scss" scoped>
.sub-image-title{
    &__contents{
        position: relative;
    }
    &__title{
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-50%);
        .tit{
            line-height: .8;
        }
    }
}
</style>