<template>
    <app-primary> 
        <template #subHead>
            <sub-visual sh="Success Story">
                <template #titHead>S</template>
                <template #tit>uccess Story</template>
            </sub-visual>
        </template>
        <page-section class="page-section--first page-section--last">
            <story-list/>
        </page-section>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubVisual from "@/sets/styles/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import StoryList from "@/components/client/board/skin/story/list.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitMedium from "@/components/publish/styles/typography/u-tit-medium.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubVisual,
        PageSection,
        StoryList,
        UTxtDefault,
        UTxtMedium,
        UTitDefault,
        UTitMedium,
    },
    data() {
        return {
        };
    },
};
</script>

<style lang="scss" scoped></style>
