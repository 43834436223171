<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>divider-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6" md="3">
                <divider-primary></divider-primary>
            </v-col>
            <v-col cols="6" md="3">
                <divider-secondary></divider-secondary>
            </v-col>
            <v-col cols="6" md="2">
                <divider-tertiary></divider-tertiary>
            </v-col>
            <v-col cols="6" md="4">
                <divider-quarternary>Divider Quarternary</divider-quarternary>
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Display -->
        <h1>Display</h1>
        <p><u>vertical</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-divider vertical class="h-40px"></v-divider>
            </v-col>
        </v-row>
        <!-- // Display -->

        <v-divider class="my-30px" />

        <!-- Text -->
        <h1>Text</h1>
        <h3>{location} : top, bottom, center</h3>
        <p><u>v-divider--{location}</u></p>
        <p><u>v-divider__txt</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <div class="v-divider--top">
                    <span class="v-divider__txt">Divider</span>
                    <v-divider />
                </div>
            </v-col>
            <v-col cols="6">
                <div class="v-divider--bottom">
                    <span class="v-divider__txt">Divider</span>
                    <v-divider />
                </div>
            </v-col>
            <v-col cols="6">
                <div class="v-divider--center">
                    <span class="v-divider__txt">Divider</span>
                    <v-divider />
                </div>
            </v-col>
        </v-row>
        <!-- // Text -->

        <v-divider class="my-30px" />

        <!-- Thickness -->
        <h1>Thickness</h1>
        <p>인라인스타일로 넣어줍니다.</p>
        <p><u>style="border-width: 2px 0 0;"</u></p>
        <p><u>vertical style="border-width: 0 2px 0 0;"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <v-divider style="border-width: 10px 0 0"></v-divider>
            </v-col>
            <v-col cols="auto">
                <v-divider vertical class="h-40px" style="border-width: 0 6px 0 0"></v-divider>
            </v-col>
        </v-row>
        <!-- // Thickness -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>class="{color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="6">
                <v-divider class="primary"></v-divider>
            </v-col>
            <v-col cols="6">
                <v-divider vertical class="h-40px secondary"></v-divider>
            </v-col>
        </v-row>
        <!-- // Color -->
    </div>
</template>

<script>
import GuideApp from "./guide-app.vue";

import DividerPrimary from "@/components/publish/parents/dividers/divider-primary.vue";
import DividerSecondary from "@/components/publish/parents/dividers/divider-secondary.vue";
import DividerTertiary from "@/components/publish/parents/dividers/divider-tertiary.vue";
import DividerQuarternary from "@/components/publish/parents/dividers/divider-quarternary.vue";

export default {
    props: {},
    components: {
        GuideApp,

        DividerPrimary,
        DividerSecondary,
        DividerTertiary,
        DividerQuarternary,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
