<template>
    <scrolldown v-bind="$attrs" class="scrolldown--double-arrow">
        <template v-if="reverse">
            <div class="scrolldown__txt">Scroll Down</div>
            <div class="scrolldown__arrow">
                <slot v-if="this.$slots['arrow']" name="arrow" />
                <template v-else>
                    <icon-chevron size="small" direction="down" />
                    <icon-chevron size="small" direction="down" />
                </template>
            </div>
        </template>
        <template v-else>
            <div class="scrolldown__arrow">
                <slot v-if="this.$slots['arrow']" name="arrow" />
                <template v-else>
                    <icon-chevron size="small" direction="down" />
                    <icon-chevron size="small" direction="down" />
                </template>
            </div>
            <div class="scrolldown__txt">Scroll Down</div>
        </template>
    </scrolldown>
</template>

<script>
import Scrolldown from "@/components/publish/styles/scrolldowns/scrolldown.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";

export default {
    props: {
        reverse: { type: Boolean, default: false },
    },
    components: {
        Scrolldown,
        IconChevron,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
