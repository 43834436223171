<template>
    <div>
        <!-- Size -->
        <h1>Size</h1>
        <h3>{size} : xx-small, x-small, small, (기본), large, x-large, xx-large</h3>
        <p>xx-small, xx-large는 클래스로 입력해주세요.</p>
        <p><u>{size}"</u></p>
        <p><u>class="v-size--{size}"</u></p>
        <br />

        <!-- S: Default -->
        <h2>Default</h2>
        <v-row align="center">
            <v-col cols="auto">
                <v-btn class="v-size--xx-small">2Xsmall</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn x-small>XSmall</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn small>Small</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn>Default</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn large>Large</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn x-large>XLarge</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn class="v-size--xx-large">2XLarge</v-btn>
            </v-col>
        </v-row>
        <!-- E: Default -->
        <br />

        <!-- S: Text -->
        <h2>Text</h2>
        <v-row align="center">
            <v-col cols="auto">
                <v-btn text class="v-size--xx-small">2Xsmall</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn x-small text>XSmall</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn small text>Small</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn text>Default</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn large text>Large</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn x-large text>XLarge</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn text class="v-size--xx-large">2XLarge</v-btn>
            </v-col>
        </v-row>
        <!-- E: Text -->
        <br />

        <!-- S: Text fit -->
        <h2>Text fit</h2>
        <v-row align="center">
            <v-col cols="auto">
                <v-btn class="v-size--xx-small v-btn--text-fit">2Xsmall</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn x-small class="v-btn--text-fit">XSmall</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn small class="v-btn--text-fit">Small</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn class="v-btn--text-fit">Default</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn large class="v-btn--text-fit">Large</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn x-large class="v-btn--text-fit">XLarge</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn class="v-size--xx-large v-btn--text-fit">2XLarge</v-btn>
            </v-col>
        </v-row>
        <!-- E: Text fit -->
        <br />

        <!-- S: Icon -->
        <h2>Icon</h2>
        <v-row align="center">
            <v-col cols="auto">
                <v-btn icon class="v-size--xx-small"><u-icon>edit</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon x-small><u-icon>edit</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon small><u-icon>edit</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon><u-icon>edit</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon large><u-icon>edit</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon x-large><u-icon>edit</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn icon class="v-size--xx-large"><u-icon>edit</u-icon></v-btn>
            </v-col>
        </v-row>
        <!-- E: Icon -->
        <!-- // Size -->

        <v-divider class="my-30px" />

        <!-- Wrap -->
        <h1>Wrap</h1>
        <p><u>class="btn-wrap--{}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <div class="btn-wrap btn-wrap--sm">
                    <v-row>
                        <v-col cols="auto">
                            <v-btn color="primary">Small</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="primary">Small</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="primary">Small</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="auto">
                <div class="btn-wrap">
                    <v-row>
                        <v-col cols="auto">
                            <v-btn color="primary">Default</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="primary">Default</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="primary">Default</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="auto">
                <div class="btn-wrap btn-wrap--lg">
                    <v-row>
                        <v-col cols="auto">
                            <v-btn color="primary">Large</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="primary">Large</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="primary">Large</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <!-- // Wrap -->

        <v-divider class="my-30px" />

        <!-- Width -->
        <h1>Width</h1>
        <p>block은 입력방법이 다릅니다. 아래 예시를 참조해주세요.</p>
        <p><u>w-{breakpoint}-{size}</u></p>
        <p><u>v-btn--width-fixed</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn color="primary" class="min-w-120px min-w-md-200px">Custom Width</v-btn>
            </v-col>
            <v-col cols="12">
                <v-btn block color="primary">Block</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="v-size--xx-small v-btn--width-fixed">2XS</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn x-small color="primary" class="v-btn--width-fixed">XS</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn small color="primary" class="v-btn--width-fixed">Small</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="v-btn--width-fixed">Default</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn large color="primary" class="v-btn--width-fixed">Large</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn x-large color="primary" class="v-btn--width-fixed">XLarge</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="v-size--xx-large v-btn--width-fixed">2XLarge</v-btn>
            </v-col>
        </v-row>
        <!-- // Width -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <!-- S: Default -->
        <h2>Default</h2>
        <p><u>color="{color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn color="primary">Primary</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="secondary">Secondary</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="accent">Accent</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="red">Red</v-btn>
            </v-col>
        </v-row>
        <!-- E: Default -->
        <br />

        <!-- S: Border -->
        <h2>Border</h2>
        <p>outlined일때 테두리색상만 변경하고 싶다면 아래의 방법을 사용해주세요.</p>
        <p><u>class="{color}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn outlined color="primary" class="secondary">Primary</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined color="secondary" class="grey lighten-3">Secondary</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined color="accent" class="grey">Accent</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined color="grey" class="primary">Grey</v-btn>
            </v-col>
        </v-row>
        <!-- E: Border -->
        <br />
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- State -->
        <h1>State</h1>
        <!-- S: Disabled -->
        <h2>Disabled</h2>
        <p><u>disabled</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn disabled color="primary">Default</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn disabled outlined color="primary">Outlined</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn disabled text color="primary">Text</v-btn>
            </v-col>
        </v-row>
        <!-- E: Disabled -->
        <br />

        <!-- S: None Active Bg -->
        <h2>None Active Bg</h2>
        <p>hover, active시 효과가 없습니다.</p>
        <p><u>v-btn--none-active-bg</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn color="primary" class="v-btn--none-active-bg">Default</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn outlined color="primary" class="v-btn--none-active-bg">Outlined</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn text color="primary" class="v-btn--none-active-bg">Text</v-btn>
            </v-col>
        </v-row>
        <!-- E: None Active Bg -->
        <!-- // State -->

        <v-divider class="my-30px" />

        <!-- Rounded -->
        <h1>Rounded</h1>
        <h3>{rounded} : (기본), xs, sm, lg, xl, pill, tile</h3>
        <p><u>class="rounded-{rounded}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn color="primary">Default</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="rounded">Rounded</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="rounded-xs">Rounded XS</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="rounded-sm">Rounded Sm</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="rounded-lg">Rounded Lg</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="rounded-xl">Rounded XL</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="rounded-pill">Rounded Pill</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn tile color="primary">Tile</v-btn>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="auto">
                <v-btn fab color="primary"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn fab color="primary" class="rounded"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn fab color="primary" class="rounded-xs"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn fab color="primary" class="rounded-sm"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn fab color="primary" class="rounded-lg"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn fab color="primary" class="rounded-xl"><u-icon>favorite</u-icon></v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn fab tile color="primary"><u-icon>favorite</u-icon></v-btn>
            </v-col>
        </v-row>
        <!-- // Rounded -->

        <v-divider class="my-30px" />

        <!-- Icon -->
        <h1>Icon</h1>
        <h3>{direction} : left, right</h3>
        <p>vue에서 렌더링 하는데 소모하는 시간이 있어 되도록이면 icon=""을 사용하지 않고 u-icon으로 아이콘을 추가합니다.</p>
        <p><u>class="v-icon--{direction}"</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <v-btn color="primary">
                    <u-icon class="v-icon--left">favorite</u-icon>
                    Prepend Icon
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary">
                    Append Icon
                    <u-icon class="v-icon--right">favorite</u-icon>
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="v-btn--width-fixed">
                    <u-icon class="v-icon--left">favorite</u-icon>
                    P Icon
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" class="v-btn--width-fixed">
                    A Icon
                    <u-icon class="v-icon--right">favorite</u-icon>
                </v-btn>
            </v-col>
        </v-row>
        <!-- // Icon -->
    </div>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        UIcon,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
