var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "Business"
          },
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._v("B")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._v("usiness")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('v-row', {
    staticClass: "row--xl mx-xl-n60px"
  }, _vm._l(_vm.business, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "px-xl-60px",
      class: index !== 0 ? 'mt-20px mt-sm-0' : '',
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('v-card', {
      staticClass: "business-item v-card--none-active-bg",
      class: {
        'business-item--link': item.href
      },
      attrs: {
        "href": item.href ? item.href : null,
        "target": item.href ? '_blank' : null,
        "flat": "",
        "tile": "",
        "max-width": "660"
      }
    }, [_c('v-img', {
      staticClass: "business-item__img w-100",
      attrs: {
        "data-aos": "fade-up",
        "src": item.image,
        "max-width": "660",
        "aspect-ratio": 660 / 220
      }
    }, [item.href ? _c('div', {
      staticClass: "business-item__img-inner"
    }, [_c('v-row', {
      staticClass: "row--xs white--text",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "font-size-14 font-weight-medium"
    }, [_vm._v("사이트로 이동")])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('icon-arrow-primary', {
      staticClass: "mt-4px",
      attrs: {
        "direction": "right",
        "size": "small"
      }
    })], 1)], 1)], 1) : _vm._e()]), _c('div', {
      staticClass: "pt-16px pt-md-24px"
    }, [_c('u-tit-default', {
      staticClass: "business-item__index tit--sm grey--text text--lighten-1 font-weight-regular font-secondary mb-8px mb-md-16px",
      attrs: {
        "data-aos": "fade-up"
      }
    }, [index < 9 ? _c('span', [_vm._v("0")]) : _vm._e(), _vm._v(_vm._s(index + 1) + " ")]), _c('v-row', {
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "200",
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('u-tit-medium', {
      staticClass: "tit--sm line-height-14"
    }, [_vm._v(_vm._s(item.title))])], 1), item.href ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('btn-primary', {
      staticClass: "v-btn--none-active-bg",
      attrs: {
        "small": ""
      }
    }, [_vm._v("자세히 보기")])], 1) : _vm._e()], 1), _c('u-txt-default', {
      staticClass: "txt--sm break-keep mt-8px mt-md-16px",
      attrs: {
        "data-aos": "fade-up",
        "data-aos-delay": "400"
      }
    }, [_vm._v(_vm._s(item.txt))])], 1)], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }