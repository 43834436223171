var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tabs', {
    staticClass: "mb-30px",
    attrs: {
      "grow": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("Header Default")]), _c('v-tab', [_vm._v("판심 법무법인")]), _c('v-tab', [_vm._v("Gnb")]), _c('v-tab', [_vm._v("Lnb")]), _c('v-tab', [_vm._v("User Items")]), _c('v-tab', [_vm._v("Language Items")]), _c('v-tab', [_vm._v("Header Search")])], 1), _c('v-window', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-window-item', [_c('HeadersDefault')], 1), _c('v-window-item', [_c('HeadersLogo')], 1), _c('v-window-item', [_c('HeadersGnb')], 1), _c('v-window-item', [_c('HeadersLnb')], 1), _c('v-window-item', [_c('HeadersUserItems')], 1), _c('v-window-item', [_c('HeadersLanguageItems')], 1), _c('v-window-item', [_c('HeadersHeaderSearch')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }