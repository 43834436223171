<template>
    <div>
        <!-- Parents -->
        <h1>Parents</h1>
        <p><u>user-items-{parents}</u></p>
        <br />
        <v-row align="center">
            <v-col cols="auto">
                <user-items-primary />
            </v-col>
            <v-col cols="auto" class="grey">
                <user-items-secondary />
            </v-col>
        </v-row>
        <!-- // Parents -->

        <v-divider class="my-30px" />

        <!-- Styles -->
        <h1>Styles</h1>
        <!-- S: Icon -->
        <h2>Icon</h2>
        <p><u>u-user-items-icon</u></p>
        <br />
        <u-user-items-icon></u-user-items-icon>
        <!-- E: Icon -->
        <br />

        <!-- S: Text -->
        <h2>Text</h2>
        <p><u>u-user-items-text</u></p>
        <br />
        <u-user-items-text></u-user-items-text>
        <!-- E: Text -->
        <br />

        <!-- S: Icon Text -->
        <h2>Icon Text</h2>
        <p><u>u-user-items-icon-text</u></p>
        <br />
        <u-user-items-icon-text></u-user-items-icon-text>
        <!-- E: Icon Text -->
        <!-- // Styles -->

        <v-divider class="my-30px" />

        <!-- Color -->
        <h1>Color</h1>
        <p><u>user-items--light</u></p>
        <br />
        <div class="grey pa-20px">
            <v-row align="center">
                <v-col cols="auto">
                    <u-user-items-icon class="user-items--light"></u-user-items-icon>
                </v-col>
                <v-col cols="auto">
                    <u-user-items-text class="user-items--light"></u-user-items-text>
                </v-col>
                <v-col cols="auto">
                    <u-user-items-icon-text class="user-items--light"></u-user-items-icon-text>
                </v-col>
            </v-row>
        </div>
        <!-- // Color -->

        <v-divider class="my-30px" />

        <!-- Boolean -->
        <h1>Boolean</h1>
        <!-- S: Icon -->
        <h2>Icon</h2>
        <p><u>icon</u></p>
        <p><u>&lt;user-items icon&gt;&lt;/user-items&gt;</u></p>
        <!-- E: Icon -->
        <br />

        <!-- S: Text -->
        <h2>Text</h2>
        <p><u>text</u></p>
        <p><u>&lt;user-items text&gt;&lt;/user-items&gt;</u></p>
        <!-- E: Text -->
        <!-- // Boolean -->
    </div>
</template>

<script>
import UserItemsPrimary from "@/components/publish/parents/headers/user-items-primary.vue";
import UserItemsSecondary from "@/components/publish/parents/headers/user-items-secondary.vue";

import UUserItemsIcon from "@/components/publish/styles/headers/u-user-items-icon.vue";
import UUserItemsText from "@/components/publish/styles/headers/u-user-items-text.vue";
import UUserItemsIconText from "@/components/publish/styles/headers/u-user-items-icon-text.vue";

import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        UserItemsPrimary,
        UserItemsSecondary,

        UUserItemsIcon,
        UUserItemsText,
        UUserItemsIconText,

        UIcon,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
